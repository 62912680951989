<template>
  <div>
    <Nav />
    <div class="docs-container d-flex flex-row .justify-content-md-between">
      <!-- <div class="sidebar p-2" role="navigation" aria-label="main navigation"> -->

      <div class="fix-sidebar p-3 ">
        <div class="sidebar-heading">DBOO 0.8.0 Documentation</div>
        <ul>
          <li>
            <a href="#introduction" class="current">Introduction</a>
            <ul>
              <li>
                <a href="#quick-start">Quick start</a>
                <ul>
                  <li>
                    <a href="#installation">Installation</a>
                  </li>
                  <li>
                    <a href="#code-example-c">Code Example C++</a>
                  </li>
                  <li><a href="#build">Build</a></li>
                </ul>
              </li>
              <li><a href="#features">Features</a></li>
            </ul>
          </li>
          <li>
            <router-link to="/installation">
              <a href="#">Installation</a></router-link
            >
          </li>
          <li>
            <router-link to="/getstarted"
              ><a href="#">Getting Started</a></router-link
            >
          </li>
          <li>
            <router-link to="/concept"><a href="#">Concept</a></router-link>
          </li>
          <li>
            <router-link to="/overview"><a href="#">Overview</a></router-link>
          </li>
          <br />
          <li><a href="#">class odb</a></li>
          <li><a href="#">class obj-id</a></li>
          <li><a href="#">class cls_base</a></li>
          <li><a href="#">class ref</a></li>
          <li><a href="#">Exceptions</a></li>
        </ul>
      </div>

      <div class="main-content p-4">
        <div class="content">
          <div class="section" id="introduction">
            <h1 class="heading">
              Introduction<a
                class="headerlink"
                href="#introduction"
                title="Permalink to this headline"
              ></a>
            </h1>
            <p>
              DBOO is an object oriented database. It is a client server
              application with the client being linked with the DBOO library.
            </p>
            <div class="section" id="quick-start">
              <h2>
                Quick Start<a
                  class="headerlink"
                  href="#quick-start"
                  title="Permalink to this headline"
                ></a>
              </h2>
              <div class="section" id="installation">
                <h3>
                  Installation<a
                    class="headerlink"
                    href="#installation"
                    title="Permalink to this headline"
                  ></a>
                </h3>
                <p>Download the server package from here.</p>
                <p>
                  Download the SDK with header files, libraries and examples
                  here. Then unzip/unpack the SDK in a place reachable by your
                  compiler.
                </p>
              </div>
              <div class="section" id="code-example-c">
                <h3>
                  Code Example C++<a
                    class="headerlink"
                    href="#code-example-c"
                    title="Permalink to this headline"
                  ></a>
                </h3>
                <p>
                  This example is found in the SDK’s :file:
                  <cite>examples</cite> directory. You can easily build it with
                  the provided cmake build script.
                </p>
                <div class="highlight-cpp notranslate">
                  <div class="highlight">
                    <pre><code><span></span><span class="cp">#include</span> <span class="cpf">&lt;dboo/odb.h&gt;</span><span class="cp"></span>

  <span class="cp">#include</span> <span class="cpf">&lt;string&gt;</span><span class="cp"></span>
  <span class="cp">#include</span> <span class="cpf">&lt;vector&gt;</span><span class="cp"></span>
  <span class="cp">#include</span> <span class="cpf">&lt;iostream&gt;</span><span class="cp"></span>

  <span class="k">using</span> <span class="n">std</span><span class="o">::</span><span class="n">string</span><span class="p">;</span>
  <span class="k">using</span> <span class="n">std</span><span class="o">::</span><span class="n">vector</span><span class="p">;</span>
  <span class="k">using</span> <span class="n">std</span><span class="o">::</span><span class="n">cout</span><span class="p">;</span>
  <span class="k">using</span> <span class="n">std</span><span class="o">::</span><span class="n">endl</span><span class="p">;</span>

  <span class="k">class</span> <span class="nc">Item</span>
  <span class="p">{</span>
  <span class="k">public</span><span class="o">:</span>
  <span class="c1">// must always have a default constructor</span>
  <span class="n">Item</span><span class="p">()</span> <span class="o">=</span> <span class="k">default</span><span class="p">;</span>
  <span class="n">Item</span><span class="p">(</span><span class="k">const</span> <span class="n">string</span><span class="o">&amp;</span> <span class="n">name</span><span class="p">,</span> <span class="kt">int</span> <span class="n">quantity</span><span class="p">,</span> <span class="k">const</span> <span class="n">string</span><span class="o">&amp;</span> <span class="n">unit</span><span class="p">)</span>
  <span class="o">:</span> <span class="n">name</span><span class="p">{</span><span class="n">name</span><span class="p">},</span> <span class="n">quantity</span><span class="p">{</span><span class="n">quantity</span><span class="p">},</span> <span class="n">unit</span><span class="p">{</span><span class="n">unit</span><span class="p">}{}</span>
  <span class="n">string</span> <span class="n">name</span><span class="p">;</span>
  <span class="kt">int</span>    <span class="n">quantity</span><span class="p">;</span>
  <span class="n">string</span> <span class="n">unit</span><span class="p">;</span>
  <span class="kt">bool</span>   <span class="n">picked</span> <span class="o">=</span> <span class="nb">false</span><span class="p">;</span>

  <span class="c1">// definitions for reflection library to pick up</span>
  <span class="c1">// member fields</span>
  <span class="k">static</span> <span class="kt">void</span> <span class="nf">osl_class_init</span><span class="p">(</span><span class="n">dboo</span><span class="o">::</span><span class="n">cls</span><span class="o">&lt;</span><span class="n">Item</span><span class="o">&gt;&amp;</span> <span class="n">c</span><span class="p">)</span> <span class="p">{</span>
  <span class="n">c</span><span class="p">.</span><span class="n">member</span><span class="p">(</span><span class="o">&amp;</span><span class="n">Item</span><span class="o">::</span><span class="n">name</span>    <span class="p">,</span> <span class="s">&quot;name&quot;</span><span class="p">);</span>
  <span class="n">c</span><span class="p">.</span><span class="n">member</span><span class="p">(</span><span class="o">&amp;</span><span class="n">Item</span><span class="o">::</span><span class="n">quantity</span><span class="p">,</span> <span class="s">&quot;quantity&quot;</span><span class="p">);</span>
  <span class="n">c</span><span class="p">.</span><span class="n">member</span><span class="p">(</span><span class="o">&amp;</span><span class="n">Item</span><span class="o">::</span><span class="n">unit</span>    <span class="p">,</span> <span class="s">&quot;unit&quot;</span><span class="p">);</span>
  <span class="n">c</span><span class="p">.</span><span class="n">member</span><span class="p">(</span><span class="o">&amp;</span><span class="n">Item</span><span class="o">::</span><span class="n">picked</span>  <span class="p">,</span> <span class="s">&quot;picked&quot;</span><span class="p">);</span>
  <span class="p">}</span>
  <span class="c1">// declare/define class object for the class Item</span>
  <span class="k">static</span> <span class="kr">inline</span> <span class="n">dboo</span><span class="o">::</span><span class="n">cls</span><span class="o">&lt;</span><span class="n">Item</span><span class="o">&gt;</span> <span class="n">cls</span><span class="p">{</span><span class="s">&quot;Item&quot;</span><span class="p">};</span>
  <span class="p">};</span>

  <span class="k">class</span> <span class="nc">ShoppingList</span>
  <span class="p">{</span>
  <span class="k">public</span><span class="o">:</span>
  <span class="c1">// must always have a default constructor</span>
  <span class="n">ShoppingList</span><span class="p">()</span> <span class="o">=</span> <span class="k">default</span><span class="p">;</span>
  <span class="n">ShoppingList</span><span class="p">(</span><span class="k">const</span> <span class="n">string</span><span class="o">&amp;</span> <span class="n">name</span><span class="p">)</span> <span class="o">:</span> <span class="n">name</span><span class="p">{</span><span class="n">name</span><span class="p">}</span> <span class="p">{}</span>

  <span class="kt">void</span> <span class="n">add</span><span class="p">(</span><span class="k">const</span> <span class="n">string</span><span class="o">&amp;</span> <span class="n">item</span><span class="p">,</span> <span class="kt">int</span> <span class="n">quantity</span><span class="p">,</span> <span class="k">const</span> <span class="n">string</span><span class="o">&amp;</span> <span class="n">unit</span><span class="p">)</span> <span class="p">{</span>
  <span class="n">items</span><span class="p">.</span><span class="n">emplace_back</span><span class="p">(</span><span class="n">item</span><span class="p">,</span> <span class="n">quantity</span><span class="p">,</span> <span class="n">unit</span><span class="p">);</span>
  <span class="p">}</span>

  <span class="n">string</span> <span class="n">name</span><span class="p">;</span>
  <span class="n">vector</span><span class="o">&lt;</span><span class="n">Item</span><span class="o">&gt;</span> <span class="n">items</span><span class="p">;</span>

  <span class="k">public</span><span class="o">:</span>
  <span class="c1">// declare reflection initialization function and class</span>
  <span class="c1">// inside class (they can be declared outside, see docs). </span>
  <span class="k">static</span> <span class="kt">void</span> <span class="n">osl_class_init</span><span class="p">(</span><span class="n">dboo</span><span class="o">::</span><span class="n">cls</span><span class="o">&lt;</span><span class="n">ShoppingList</span><span class="o">&gt;&amp;</span> <span class="n">c</span><span class="p">);</span>
  <span class="k">static</span> <span class="n">dboo</span><span class="o">::</span><span class="n">cls</span><span class="o">&lt;</span><span class="n">ShoppingList</span><span class="o">&gt;</span> <span class="n">cls</span><span class="p">;</span>
  <span class="p">};</span>
  <span class="c1">// alternative way, put definitions outside class</span>
  <span class="kt">void</span> <span class="n">ShoppingList</span><span class="o">::</span><span class="n">osl_class_init</span><span class="p">(</span><span class="n">dboo</span><span class="o">::</span><span class="n">cls</span><span class="o">&lt;</span><span class="n">ShoppingList</span><span class="o">&gt;&amp;</span> <span class="n">c</span><span class="p">)</span>
  <span class="p">{</span>
  <span class="c1">// declare each member field here.</span>
  <span class="n">c</span><span class="p">.</span><span class="n">member</span><span class="p">(</span><span class="o">&amp;</span><span class="n">ShoppingList</span><span class="o">::</span><span class="n">name</span><span class="p">,</span> <span class="s">&quot;name&quot;</span><span class="p">);</span>
  <span class="n">c</span><span class="p">.</span><span class="n">member</span><span class="p">(</span><span class="o">&amp;</span><span class="n">ShoppingList</span><span class="o">::</span><span class="n">items</span><span class="p">,</span> <span class="s">&quot;items&quot;</span><span class="p">);</span>
  <span class="p">}</span>
  <span class="c1">// declare the class object for the class ShoppingList</span>
  <span class="n">dboo</span><span class="o">::</span><span class="n">cls</span><span class="o">&lt;</span><span class="n">Shopping List</span><span class="o">&gt;</span> <span class="n">ShoppingList</span><span class="o">::</span><span class="n">cls</span><span class="p">{</span><span class="s">&quot;ShoppingList&quot;</span><span class="p">};</span>

  <span class="kt">int</span> <span class="nf">main</span><span class="p">(</span><span class="kt">int</span> <span class="n">argc</span><span class="p">,</span> <span class="kt">char</span><span class="o">**</span><span class="p">)</span>
  <span class="p">{</span>
  <span class="c1">// initialize the reflection/introspection library</span>
  <span class="n">dboo</span><span class="o">::</span><span class="n">init</span><span class="p">();</span>

  <span class="c1">// connect to the database</span>
  <span class="n">dboo</span><span class="o">::</span><span class="n">odb</span> <span class="n">db</span><span class="p">;</span>
  <span class="n">db</span><span class="p">.</span><span class="n">connect</span><span class="p">(</span><span class="s">&quot;localhost&quot;</span><span class="p">,</span> <span class="mi">8822</span><span class="p">,</span> <span class="s">&quot;examples&quot;</span><span class="p">,</span> <span class="s">&quot;me&quot;</span><span class="p">,</span> <span class="s">&quot;password&quot;</span><span class="p">);</span>

  <span class="n">ShoppingList</span> <span class="n">mylist</span><span class="p">(</span><span class="s">&quot;Groceries&quot;</span><span class="p">);</span>
  <span class="n">mylist</span><span class="p">.</span><span class="n">add</span><span class="p">(</span><span class="s">&quot;Tomatoes&quot;</span><span class="p">,</span> <span class="mi">4</span><span class="p">,</span> <span class="s">&quot;&quot;</span><span class="p">);</span>
  <span class="n">mylist</span><span class="p">.</span><span class="n">add</span><span class="p">(</span><span class="s">&quot;Capsicum&quot;</span><span class="p">,</span> <span class="mi">1</span><span class="p">,</span> <span class="s">&quot;&quot;</span><span class="p">);</span>
  <span class="n">mylist</span><span class="p">.</span><span class="n">add</span><span class="p">(</span><span class="s">&quot;Flour&quot;</span><span class="p">,</span> <span class="mi">2</span><span class="p">,</span> <span class="s">&quot;kg&quot;</span><span class="p">);</span>
  <span class="n">mylist</span><span class="p">.</span><span class="n">add</span><span class="p">(</span><span class="s">&quot;Butter&quot;</span><span class="p">,</span> <span class="mi">500</span><span class="p">,</span> <span class="s">&quot;g&quot;</span><span class="p">);</span>

  <span class="c1">// store shopping list in database</span>
  <span class="n">db</span><span class="p">.</span><span class="n">commit</span><span class="p">(</span><span class="o">&amp;</span><span class="n">mylist</span><span class="p">);</span>

  <span class="c1">// retrieve all shopping lists from the database</span>
  <span class="n">vector</span><span class="o">&lt;</span><span class="n">ShoppingList</span><span class="o">*&gt;</span> <span class="n">all_lists</span><span class="p">;</span>
  <span class="n">db</span><span class="p">.</span><span class="n">select</span><span class="o">&lt;</span><span class="n">ShoppingList</span><span class="o">&gt;</span><span class="p">(</span><span class="n">all_lists</span><span class="p">);</span>

  <span class="c1">// print all shopping lists</span>
  <span class="k">for</span> <span class="p">(</span><span class="k">auto</span><span class="o">&amp;</span> <span class="nl">list</span> <span class="p">:</span> <span class="n">all_lists</span><span class="p">)</span> <span class="p">{</span>
  <span class="n">cout</span> <span class="o">&lt;&lt;</span> <span class="n">list</span><span class="o">-&gt;</span><span class="n">name</span> <span class="o">&lt;&lt;</span> <span class="s">&quot;:&quot;</span> <span class="o">&lt;&lt;</span> <span class="n">endl</span><span class="p">;</span>
  <span class="k">for</span> <span class="p">(</span><span class="k">auto</span><span class="o">&amp;</span> <span class="nl">item</span> <span class="p">:</span> <span class="n">list</span><span class="o">-&gt;</span><span class="n">items</span><span class="p">)</span> <span class="p">{</span>
  <span class="n">cout</span> <span class="o">&lt;&lt;</span> <span class="s">&quot;  &quot;</span> <span class="o">&lt;&lt;</span> <span class="n">item</span><span class="p">.</span><span class="n">name</span> <span class="o">&lt;&lt;</span> <span class="s">&quot; &quot;</span> 
  <span class="o">&lt;&lt;</span> <span class="n">item</span><span class="p">.</span><span class="n">quantity</span> <span class="o">&lt;&lt;</span> <span class="s">&quot; &quot;</span> 
  <span class="o">&lt;&lt;</span> <span class="n">item</span><span class="p">.</span><span class="n">unit</span> <span class="o">&lt;&lt;</span> <span class="s">&quot; &quot;</span> <span class="o">&lt;&lt;</span> <span class="p">(</span><span class="n">item</span><span class="p">.</span><span class="n">picked</span> <span class="o">?</span> <span class="s">&quot;picked!&quot;</span> <span class="o">:</span> <span class="s">&quot;&quot;</span><span class="p">)</span> <span class="o">&lt;&lt;</span> <span class="n">endl</span><span class="p">;</span>
  <span class="p">}</span>
  <span class="p">}</span>

  <span class="k">return</span> <span class="mi">0</span><span class="p">;</span>
  <span class="p">}</span>
                      </code>
                  </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section" id="build">
            <h3>
              Build<a
                class="headerlink"
                href="#build"
                title="Permalink to this headline"
              ></a>
            </h3>
            <div class="highlight-command notranslate">
              <div class="highlight">
                <pre><code><span></span>
  &gt; cd &lt;installation directory&gt;/dboo/examples
  &gt; mkdir build
  &gt; cd build
  &gt; cmake ..
  &gt; make
  &gt; ./dboo_test
                  </code>
                </pre>
              </div>
            </div>
          </div>
          <div class="section" id="features">
            <h2>
              Features<a
                class="headerlink"
                href="#features"
                title="Permalink to this headline"
              ></a>
            </h2>

            <p>Among the benefits with DBOO are:</p>
            <blockquote>
              <div>
                <ul class="simple">
                  <li class="simple-li">
                    <p>
                      Available for macOS, Windows, Linux
                    </p>
                  </li>
                  <li class="simple-li">
                    <p>
                      For languages: - C++ - .Net (C#, managed c++ etc) - Java -
                      Node JS - Python - PHP
                    </p>
                  </li>
                  <li class="simple-li">
                    <p>
                      The data interface is the programming language’s data
                      types.
                    </p>
                  </li>
                  <li class="simple-li">
                    <p>Resolves and links objects graphs</p>
                  </li>
                  <li class="simple-li">
                    <p>
                      Transactional, atomic commits for any number of objects
                    </p>
                  </li>
                  <li class="simple-li"><p>Unlimited object size</p></li>
                  <li class="simple-li"><p>64 bit keys</p></li>
                  <li class="simple-li">
                    <p>
                      Simple query language and join queries expressed in
                      compiled code
                    </p>
                  </li>
                  <li class="simple-li"><p>No SQL in the background</p></li>
                </ul>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Docs",
  components: {
    Nav,
    Footer
  }
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  z-index: 10;
  top: 61px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-heading {
  font-weight: bold;
}
.current {
  color: #2f80ed;
}
.main-content {
  padding-right: 1rem;
  text-align: left;
  max-width: 700px;
  padding: 35px 0;
  padding-left: 50px;
}
li {
  text-align: left;
  list-style: none;
}
a {
  text-decoration: none;
  color: #525252;
}
a:hover {
  color: orange;
}

pre code {
  overflow-x: auto;
  color: #525252;
  white-space: pre;
  line-height: 1.6rem;
  display: block;
  background-color: #f8f8f8;
  margin: 0 2px;
  border-radius: 2px;
  text-align: left;
  padding: 1.2em 1.4em;
}

.simple-li {
  list-style-type: inherit;
}

.highlight .cp {
  color: #007020;
}

.highlight .cpf {
  color: #408090;
  font-style: italic;
}

.highlight .k {
  color: #007020;
  font-weight: bold;
}

.highlight .nc {
  color: #0e84b5;
  font-weight: bold;
}

.highlight .kt {
  color: #d63200;
}

.highlight .o {
  color: #666666;
}

.highlight .c1 {
  color: #707070;
  font-style: italic;
}
</style>
