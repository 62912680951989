<template>
  <Nav />
  <div class="docs-container d-flex flex-row .justify-content-md-between">
    <!-- <div class="sidebar p-2" role="navigation" aria-label="main navigation"> -->

    <div class="fix-sidebar p-3 ">
      <div class="sidebar-heading">DBOO 0.8.0 Documentation</div>
      <ul>
        <li>
          <a href="#introduction">Introduction</a>
        </li>
        <li>
          <router-link to="/installation">
            <a href="#">Installation</a></router-link
          >
        </li>
        <li>
          <router-link to="/getstarted"
            ><a href="#">Getting Started</a></router-link
          >
        </li>
        <li>
          <router-link to="/concept"><a href="#">Concept</a></router-link>
        </li>
        <li>
          <router-link to="/overview"
            ><a href="#" class="current">Overview</a></router-link
          >
          <ul>
            <li>
              <a href="#benefits">Benefits</a>
            </li>

            <li>
              <a href="#technologies">Technologies</a>
            </li>
          </ul>
        </li>
        <br />
        <li><a href="#">class odb</a></li>
        <li><a href="#">class obj-id</a></li>
        <li><a href="#">class cls_base</a></li>
        <li><a href="#">class ref</a></li>
        <li><a href="#">Exceptions</a></li>
      </ul>
    </div>

    <div class="main-content p-4">
      <div class="content">
        <div class="section" id="overview">
          <h1 class="overview-heading">
            Overview<a
              class="headerlink"
              href="#overview"
              title="Permalink to this headline"
            ></a>
          </h1>
          <p>
            DBOO is a Distributed Computing Platform and Object Oriented
            Database. DBOO provides an easy, fast and efficient way to implement
            distributed applications. It is built from the core to take
            advantage of object oriented design all throughout from API to data
            storage.
          </p>
          <p>
            DBOO is a client / server system, very much like a traditional
            database. The DBOO server installs on a server machine and your
            application connects as a client to the server through the DBOO API.
            The DBOO API comes as a static library (c++) or dll (.Net).
          </p>
          <p>
            &lt;img src=”/Images/ClientServer Overview.png” alt=””&gt;
          </p>
          <p>
            The API knows how to store objects and linkages between objects.
            When committing objects to the server, the DBOO API knows how to
            find linked objects (through pointers/references) and sends any
            updated or new objects to the server. Retrieving objects is done
            either through select statements (through the C++/Java/C# interface)
            based on member field values or by direct object lookup based on
            object id.
          </p>
          <p>
            &lt;img src=”/Images/ClientServer Objects Transfer.png” alt=””&gt;
          </p>
          <div class="section" id="benefits">
            <h2 class="benefits-heading">
              Benefits<a
                class="headerlink"
                href="#benefits"
                title="Permalink to this headline"
              ></a>
            </h2>
            <p><strong>Faster Development Cycle</strong></p>
            <ul>
              <li>
                <p>
                  Use one language for application programming and data
                  modelling
                </p>
                <p>
                  <em
                    >If your application requires to be written in an object
                    oriented language, the drawback with existing data storage
                    solutions is usually that you need to use another language
                    for modelling the data for that storage (i.e. if storing in
                    a relational database, you design the tables etc in SQL).
                    Using DBOO, you only use C++, or you only use C# or only
                    Java for example.</em
                  >
                </p>
              </li>
              <li>
                <p>Storage data model same as runtime model</p>
                <p>
                  <em
                    >With existing solutions, you normally end up having to
                    design your classes in both your application language (e.g.
                    C++) and model them in a database tool (or SQL), or you may
                    end up designing file formats. With DBOO, you only model
                    your C++, or C#, or Java classes. There is no need for
                    duplicate data models just for storing your data.</em
                  >
                </p>
              </li>
              <li>
                <p>No storage to runtime transformation required</p>
                <p>
                  <em
                    >With DBOO, there is no need for coding the input or output
                    code in order to store or retrieve your data. With a minimum
                    of declarative code, the DBOO’s API understands how to
                    convert the runtime objects into the storage format.</em
                  >
                </p>
              </li>
            </ul>
            <p><strong>Better Data Modelling</strong></p>
            <ul>
              <li>
                <p>
                  Object oriented modelling is a better fit to many real world
                  problems
                </p>
                <p>
                  <em
                    >With DBOO, you can still use all data modelling features of
                    your favourite language. It supports inheritance (including
                    multiple in C++), and polymorphism, and object ownership
                    relations just as you would expect from an object oriented
                    language. These features would be hard or impossible to
                    model in a relational model without a performance
                    impact.</em
                  >
                </p>
              </li>
              <li>
                <p>
                  Develop processes and algorithms that fits an object oriented
                  model
                </p>
                <p>
                  <em
                    >Encapsulation of functionality and generic programming are
                    two of the core strengths in object oriented design. By
                    being able to use inheritance, Clear ODB makes it easier to
                    create applications the way that was intended with object
                    oriented design. If you are forced to mix two programming
                    paradigms such as relational and object orientation, it
                    requires more effort to create systems that have clear cut
                    interfaces; or where functionality can be reused thanks to
                    polymorphism for example.</em
                  >
                </p>
              </li>
              <li>
                <p>
                  Type safe; no casting from database types to runtime types
                  required
                </p>
                <p>
                  <em
                    >DBOO takes care of any casting, if any. Data is normally
                    stored in it’s primitive binary format. Primitives, strings,
                    pointers, classes are all converted to the expected data
                    types. There is no need for explicit casting.</em
                  >
                </p>
              </li>
            </ul>
          </div>
          <div class="section" id="technologies">
            <h2 class="technologies-heading">
              Technologies<a
                class="headerlink"
                href="#technologies"
                title="Permalink to this headline"
              ></a>
            </h2>
            <p><strong>Data Storage</strong></p>
            <ul>
              <li>
                <p>Atomic commits &amp;amp; transactional data storage</p>
                <p>
                  <em
                    >It is important that your data is saved safely and that
                    referential integrity is maintained at all times. When DBOO
                    returns successfully from a commit, you can be sure that the
                    data is stored on disk. Storing potentially a large number
                    of objects in one commit, is done as one atomic transaction.
                    Either the entire transaction is stored successfully, or
                    there are no changes to the data on the server.</em
                  >
                </p>
              </li>
            </ul>
            <p><strong>Data Distribution</strong></p>
            <ul>
              <li>
                <p>Client / server architecture</p>
                <p>
                  <em
                    >The DBOO system consists of a database server (proprietary
                    back-end) and a client interface that links as static or
                    dynamic libraries to your application code. On Windows, the
                    server runs as a standard Windows service.</em
                  >
                </p>
              </li>
            </ul>
            <p><strong>Distributed Processing</strong></p>
            <ul>
              <li>
                <p>Object queuing and distribution mechanism</p>
                <p>
                  <em
                    >DBOO has features for distributed object queues, allowing
                    for streaming of objects and makes it easy to develop
                    applications requiring distributed processing.</em
                  >
                </p>
              </li>
            </ul>
            <p><strong>Integration with OO Languages</strong></p>
            <ul class="simple">
              <li>
                <p>API for popular Object Oriented Languages:</p>
                <ul>
                  <li>
                    <p>C++ <em>(Windows, Linux, Mac OS X)</em></p>
                  </li>
                  <li>
                    <p>.Net <em>(Windows)</em></p>
                  </li>
                  <li><p>Java</p></li>
                  <li><p>NodeJS</p></li>
                  <li><p>PHP</p></li>
                  <li><p>Python</p></li>
                  <li><p>Objective C</p></li>
                </ul>
              </li>
            </ul>
            <p>
              Using DBOO enables you to design and develop truly object oriented
              solutions; allowing you to take full advantage of the benefits of
              object oriented design such as code reuse and encapsulation; and
              not having to compromise because of your data storage options.
            </p>
            <p>
              DBOO is not an object-relational mapping solution. That means that
              at no point is there a translation from object model to relational
              model.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Overview",
  components: {
    Nav,
    Footer
  }
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  z-index: 10;
  top: 61px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-heading {
  font-weight: bold;
}
.current {
  color: #2f80ed;
}
.main-content {
  padding-right: 1rem;
  text-align: left;
  max-width: 700px;
  padding: 35px 0;
  padding-left: 50px;
}
li {
  text-align: left;
  list-style: none;
}
a {
  text-decoration: none;
  color: #525252;
}
a:hover {
  color: orange;
}

pre code {
  overflow-x: auto;
  color: #525252;
  white-space: pre;
  line-height: 1.6rem;
  display: block;
  background-color: #f8f8f8;
  margin: 0 2px;
  border-radius: 2px;
  text-align: left;
  padding: 1.2em 1.4em;
}

.simple-li {
  list-style-type: inherit;
}

.highlight .cp {
  color: #007020;
}

.highlight .cpf {
  color: #408090;
  font-style: italic;
}

.highlight .k {
  color: #007020;
  font-weight: bold;
}

.highlight .nc {
  color: #0e84b5;
  font-weight: bold;
}

.highlight .kt {
  color: #d63200;
}

.highlight .o {
  color: #666666;
}

.highlight .c1 {
  color: #707070;
  font-style: italic;
}
</style>
