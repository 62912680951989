<template>
  <div id="contact-form" class="form-group">
    <form class="form">
      <h5>Message</h5>
      <input
        required
        name="email"
        placeholder="Your Work Email"
        type="email"
        autocomplete="off"
        class="form-control"
      />
      <div class="row">
        <div class="col">
          <input
            type="text"
            class="form-control"
            required
            name="name"
            placeholder="First name"
            autocomplete="off"
          />
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            required
            name="name"
            placeholder="Last name"
            autocomplete="off"
          />
        </div>
      </div>
      <input
        required
        name="name"
        placeholder="Company"
        type="text"
        autocomplete="off"
        class="form-control"
      />
      <input
        required
        name="name"
        placeholder="Job title(for helping us to find the solution that suits you the best."
        type="text"
        autocomplete="off"
        class="form-control"
      />
      <select class="form-control" id="exampleFormControlSelect1">
        <option>Job title</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </select>
      <textarea
        name="message"
        rows="6"
        placeholder="Tell us how we can help you."
        type="text"
        class="form-control"
      ></textarea>
      <button type="button" class="btn btn-outline-primary">SUBMIT</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Form"
};
</script>

<style scoped>
.form {
  margin: 3em 1.1em;
}

input,
select,
textarea {
  margin-bottom: 0.5em;
}
</style>
