<template>
  <div>
    <Nav />
    <div
      class="gettingStarted-container d-flex flex-row .justify-content-md-between"
    >
      <!-- <div class="sidebar p-2" role="navigation" aria-label="main navigation"> -->

      <div class="fix-sidebar p-3">
        <div class="sidebar-heading">DBOO 0.8.0 Documentation</div>
        <ul>
          <li>
            <a href="#">Introduction</a>
          </li>
          <li>
            <router-link to="/installation"
              ><a href="#" class="current">Installation</a></router-link
            >
            <ul>
              <li>
                <a href="#running-the-server-locally-as-yourself"
                  >Running the server locally as yourself</a
                >
              </li>
              <li>
                <a href="#configuring-the-server">Configuring the server</a>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/getstarted"
              ><a href="#">Getting Started</a></router-link
            >
          </li>
          <li>
            <router-link to="/concept"><a href="#">Concept</a></router-link>
          </li>
          <li>
            <router-link to="/overview"><a href="#">Overview</a></router-link>
          </li>
          <br />
          <li><a href="#">class odb</a></li>
          <li><a href="#">class obj-id</a></li>
          <li><a href="#">class cls_base</a></li>
          <li><a href="#">class ref</a></li>
          <li><a href="#">Exceptions</a></li>
        </ul>
      </div>

      <div class="main-content p-4">
        <div class="content">
          <div class="section" id="installation">
            <h1>
              Installation<a
                class="headerlink"
                href="#installation"
                title="Permalink to this headline"
              ></a>
            </h1>
            <p>
              Download the package for your distribution and install that. Or
              download the bz2-archive and put the files where you want them.
            </p>
            <p>
              Note that the packages contain both server and developer files.
              The same server binary may run fine on several distributions, but
              the developer files includes the libdboo.a which is built with the
              default compiler for the platform. It will most likely not work
              well with other versions of the compiler.
            </p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span>
  <span class="cp"># OpenSUSE/Leap:</span>
  <span class="o">&gt;</span> <span class="n">sudo</span> <span class="n">zypper</span> <span class="n">install</span> <span class="n">dboo</span><span class="o">-</span><span class="mf">0.8.0</span><span class="o">-</span><span class="mf">1.</span><span class="n">opensuse15</span><span class="mf">.1</span><span class="p">.</span><span class="n">x86_64</span><span class="p">.</span><span class="n">rpm</span>

  <span class="cp"># Fedora:</span>
  <span class="o">&gt;</span> <span class="n">rpm</span> <span class="o">-</span><span class="n">U</span> <span class="n">dboo</span><span class="o">-</span><span class="mf">0.8.0</span><span class="o">-</span><span class="mf">1.f</span><span class="n">c31</span><span class="p">.</span><span class="n">x86_64</span><span class="p">.</span><span class="n">rpm</span>

  <span class="cp"># CentOS:</span>
  <span class="o">&gt;</span> <span class="n">rpm</span> <span class="o">-</span><span class="n">U</span> <span class="n">dboo</span><span class="o">-</span><span class="mf">0.8.0</span><span class="o">-</span><span class="mf">1.</span><span class="n">el8</span><span class="p">.</span><span class="n">x86_64</span><span class="p">.</span><span class="n">rpm</span>

  <span class="cp"># Arch:</span>
  <span class="o">&gt;</span> <span class="n">pacman</span> <span class="o">-</span><span class="n">U</span> <span class="n">dboo</span><span class="o">-</span><span class="mf">0.8.0</span><span class="o">-</span><span class="mi">1</span><span class="o">-</span><span class="n">x86_64</span><span class="p">.</span><span class="n">pkg</span><span class="p">.</span><span class="n">tar</span><span class="p">.</span><span class="n">xz</span>

  <span class="cp"># Debian:</span>
  <span class="o">&gt;</span> <span class="n">sudo</span> <span class="n">dpkg</span> <span class="o">-</span><span class="n">i</span> <span class="n">dboo</span><span class="o">-</span><span class="mf">0.8.0</span><span class="o">-</span><span class="n">deb10</span><span class="o">-</span><span class="n">amd64</span><span class="p">.</span><span class="n">deb</span>

  <span class="cp"># Ubuntu:</span>
  <span class="o">&gt;</span> <span class="n">sudo</span> <span class="n">dpkg</span> <span class="o">-</span><span class="n">i</span> <span class="n">dboo</span><span class="o">-</span><span class="mf">0.8.0</span><span class="o">-</span><span class="n">ubuntu20</span><span class="mf">.04</span><span class="o">-</span><span class="n">amd64</span><span class="p">.</span><span class="n">deb</span>
                  </code>
                </pre>
              </div>
            </div>
            <p>On macOS, open the .pkg file and follow the instructions.</p>
            <p>
              The server installation consists of the server, dbood, and a
              manager program, dboo.
            </p>
          </div>
          <div class="section" id="running-the-server-locally-as-yourself">
            <h1>
              Running the server locally as yourself<a
                class="headerlink"
                href="#running-the-server-locally-as-yourself"
                title="Permalink to this headline"
              ></a>
            </h1>
            <p>
              The following will set up the server using default settings in
              ‘${HOME}/.dboo’. This can be useful while developing and testing
              things out. Note that this sets up the root user without password
              (by default the root user can only login from localhost). The
              default behaviour is to listen for connections on localhost:8822.
            </p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="o">&gt;</span> <span class="n">dbood</span> <span class="n">create</span>
                  </code>
                </pre>
              </div>
            </div>
            <p>You can then run the server with:</p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="o">&gt;</span> <span class="n">dbood</span>
                  </code>
                </pre>
              </div>
            </div>
            <p>For daemon mode:</p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="o">&gt;</span> <span class="n">dbood</span> <span class="o">-</span><span class="n">d</span>
                  </code>
                </pre>
              </div>
            </div>
            <p>To kill the server when in daemon mode:</p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="o">&gt;</span> <span class="n">dbood</span> <span class="o">--</span><span class="n">kill</span>
  <span class="n">or</span>
  <span class="o">&gt;</span> <span class="n">dboo</span> <span class="n">kill</span>
                  </code>
                </pre>
              </div>
            </div>
            <p>
              To set the root password, run the following command. The server
              will immediately exit afterwards and you then have to start the
              server without the –root-password argument.
            </p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="o">&gt;</span> <span class="n">dbood</span> <span class="o">--</span><span class="n">root</span><span class="o">-</span><span class="n">password</span> <span class="o">&lt;</span><span class="n">password</span><span class="o">&gt;</span>
                  </code>
                </pre>
              </div>
            </div>
          </div>
          <div class="section" id="configuring-the-server">
            <h1>
              Configuring the server<a
                class="headerlink"
                href="#configuring-the-server"
                title="Permalink to this headline"
              ></a>
            </h1>
            <p>
              In a server environment you should run the dboo server as user
              dboo and set up a proper storage location for the server and
              database files.
            </p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="n">dboo</span><span class="o">-</span><span class="n">setup</span><span class="p">.</span><span class="n">sh</span> <span class="o">&lt;</span><span class="n">user</span><span class="o">&gt;</span> <span class="o">&lt;</span><span class="n">group</span><span class="o">&gt;</span> <span class="p">[</span><span class="o">--</span><span class="n">service</span><span class="o">-</span><span class="n">name</span> <span class="o">&lt;</span><span class="n">service</span><span class="o">&gt;</span><span class="p">]</span> <span class="p">[</span><span class="o">&lt;</span><span class="n">config</span> <span class="n">file</span><span class="o">&gt;</span> <span class="o">&lt;</span><span class="n">server</span> <span class="n">database</span> <span class="n">directory</span><span class="o">&gt;</span> <span class="o">&lt;</span><span class="n">database</span> <span class="n">directory</span><span class="o">&gt;</span> <span class="p">[</span><span class="o">&lt;</span><span class="n">hostname</span><span class="o">&gt;</span> <span class="p">[</span><span class="o">&lt;</span><span class="n">port</span><span class="o">&gt;</span><span class="p">]]]</span> <span class="p">[</span><span class="o">--</span><span class="n">root</span><span class="o">-</span><span class="n">password</span> <span class="o">&lt;</span><span class="n">password</span><span class="o">&gt;</span><span class="p">]</span>
                  </code>
                </pre>
              </div>
            </div>
            <dl class="simple">
              <dt>&lt;user&gt; &amp; &lt;group&gt;</dt>
              <dd><p>The user and group dboo will will run as.</p></dd>
              <dt>&lt;config file&gt;</dt>
              <dd>
                <p>
                  The configuration file where settings are written to. If it
                  exists already, the original is overwritten.
                </p>
              </dd>
              <dt>&lt;server database directory&gt;</dt>
              <dd>
                <p>
                  The server database goes here. Relatively small depending on
                  use case. It will contain the configurations for all
                  databases, users and groups.
                </p>
              </dd>
              <dt>&lt;database directory&gt;</dt>
              <dd>
                <p>
                  The default directory for databases. A location can be named
                  for each database later when they are created (and they can be
                  moved).
                </p>
              </dd>
              <dt>&lt;hostname&gt;</dt>
              <dd><p>The listening host name. Defaults to localhost.</p></dd>
              <dt>&lt;port&gt;</dt>
              <dd>
                <p>
                  The listening port for the server. Clients connect to this
                  port.
                </p>
              </dd>
              <dt>‘–root-password’</dt>
              <dd>
                <p>
                  Initial root password. If ommitted the password will be empty.
                  It can be reset later either through dboo while the server is
                  running, or by restarting the server with –root-password
                  command.
                </p>
              </dd>
            </dl>
            <p>Example:</p>
            <div class="highlight-cpp notranslate">
              <div class="highlight">
                <pre><code><span></span><span class="o">&gt;</span> <span class="n">dboo</span><span class="o">-</span><span class="n">setup</span><span class="p">.</span><span class="n">sh</span> <span class="n">dboo</span> <span class="n">dboo</span> <span class="o">/</span><span class="n">etc</span><span class="o">/</span><span class="n">dboo</span><span class="p">.</span><span class="n">cfg</span> <span class="o">/</span><span class="n">var</span><span class="o">/</span><span class="n">dboo</span><span class="o">/</span><span class="n">server</span> <span class="o">/</span><span class="n">var</span><span class="o">/</span><span class="n">dboo</span><span class="o">/</span><span class="n">databases</span> <span class="o">/</span><span class="n">var</span><span class="o">/</span><span class="n">log</span><span class="o">/</span><span class="n">dboo</span> <span class="n">localhost</span> <span class="mi">2652</span>
                  </code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearer"></div>

    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "GetStarted",
  components: {
    Nav,
    Footer
  }
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  z-index: 10;
  top: 61px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0;
}

.sidebar-heading {
  font-weight: bold;
}
.current {
  color: #2f80ed;
}
.main-content {
  padding-right: 1rem;
  text-align: left;
  max-width: 700px;
  padding: 35px 0;
  padding-left: 50px;
}
li {
  text-align: left;
  list-style: none;
}
a {
  text-decoration: none;
  color: #525252;
}
a:hover {
  color: orange;
}

pre code {
  overflow-x: auto;
  color: #525252;
  white-space: pre;
  line-height: 1.6;
  display: block;
  background-color: #f8f8f8;
  margin: 0 2px;
  border-radius: 2px;
  text-align: left;
  padding: 1.2em 1.4em;
}

.highlight .cp {
  color: #007020;
}

.highlight .cpf {
  color: #408090;
  font-style: italic;
}

.highlight .k {
  color: #007020;
  font-weight: bold;
}

.highlight .nc {
  color: #0e84b5;
  font-weight: bold;
}

.highlight .kt {
  color: #d63200;
}

.highlight .o {
  color: #666666;
}

.highlight .c1 {
  color: #707070;
  font-style: italic;
}
</style>
