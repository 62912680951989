<template>
  <!-- <div class="overlay"></div>
  <video playsinline autoplay muted loop width="100%" height="auto">
    <source :src="videoSrc" type="video/mp4" />
    Sorry, your browser doesn't support embedded videos.
  </video> -->
  <div id="hero" class="d-fle align-items-center">
    <div class="container position-relative">
      <!-- <img
        class="logo"
        src="@/assets/dboo2.png"
        alt="DBOO visualisation"
        width="600"
        height="400"
        style="float:right; margin-top:5em; padding-left: 5em;"
      /> -->
      <!-- logo image location in hero  -->
      <!-- <router-link to="/"
          ><img
            class="logo"
            src="@/assets/logo.png"
            alt="DBOO logo"
            width="200"
            height="48"
        /></router-link> -->
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 text-center">
          <h1 class="text-center">
            <strong> DBOO</strong><small> database</small>
          </h1>
          <h1 class="text-center">
            <strong>for humans to use, for computers to run</strong>
          </h1>
          <br />
          <br />
          <h2 class="text-center">
            Do you use object oriented programming languages? DBOO is designed
            just for you!
          </h2>
        </div>
      </div>
      <!-- <p>
        Model your data with inheritance and pointers/references as in any
        object oriented design. Store complete objects graphs, complex
        structures directly to the database. Retrieve/search on member fields or
        through linked objects.
      </p>
      <p>
        Multi platform. Today available for C++, .Net and Node.JS.
      </p> -->
      <br />
      <Button class="text-center btn-lg" />
      <br />
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
export default {
  name: "Hero",
  components: {
    Button
  }
};
</script>

<style scoped>
/* #hero {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../assets/hero-bg.jpg") top center;
  background-size: cover;
  position: relative;
} */

#hero:before {
  content: "";
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  padding-top: 10rem;
}

#hero h1 {
  margin: 0;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 4.5rem;
  color: #124265;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 1.4rem;
}

@media (max-height: 800px) {
  #hero {
    height: auto;
  }
}

@media (max-width: 992px) {
  #hero {
    height: auto;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
