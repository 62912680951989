import { createApp } from "vue";
import Bootstrap from "bootstrap";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueSplit from 'vue-split-panel'

createApp(App)
  .use(store)
  .use(router)
  .use(Bootstrap)
  .use(VueSplit)
  .mount("#app");
