<template>
  <div class="gettingStarted-container">
    <Nav />
    <div class="container d-flex flex-row justify-content-md-between">
      <div
        class="sidebar p-2 pt-5"
        v-html="sidepanel"
        @click="handleClick"
        ref="sidebar"
      />
      <div 
        class="main-content" 
        ref="mainpanel">
        <div class="content">
          <span v-html="content"
                @click="handleClick"></span>
        </div>
      </div>
    </div>
    <!-- <div ref="footer">
      <Footer />
    </div> -->
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
// import Footer from "@/components/Footer.vue";
import axios from "axios";

export default {
  name: "GettingStarted",
  components: {
    Nav
    // Footer
  },
  data() {
    return {
      sidepanel: "",
      content: "",
      lastTarget: null,
      lastTargetOldStyle: {}
    };
  },
  methods: {
    loadIndex() {
      // This is hard coded to getting started
      let toload = "/contents/current/getting-started/index.html";
      axios({
        method: "GET",
        url: toload
      }).then(response => {
        this.sidepanel = response.data;
      });
    },
    loadContent(resource) {
      let toload = "/contents/current" + resource + ".html";
      axios({
        method: "GET",
        url: toload
      }).then(response => {
        this.content = response.data;
      });
    },
    getResource(link) {
      if (link.indexOf("#") != -1) {
        return link.substr(0, link.indexOf("#"));
      }
      return link;
    },
    getHashbang(link) {
      if (link.indexOf("#") != -1) {
        return link.substr(link.indexOf("#"));
      }
      return "#";
    },
    gotoURL(link) {
      let page = this.getResource(link);
      let hashbang = this.getHashbang(link);
      this.loadContent(page);
      this.$router.push(link);
      location.hash = hashbang;
    },
    handleClick(e) {
      if (e.target.matches("a")) {
        if (this.lastTarget != null) {
          this.lastTarget.style = this.lastTargetOldStyle;
        }
        this.lastTargetOldStyle = e.target.style;
        this.lastTarget = e.target;

        let url = e.target.getAttribute("href");
        this.gotoURL(url);
        e.target.style.fontWeight = "bold";
      }
      e.preventDefault();
    },
    handleScroll() {
      let b =
        this.$refs.footer.offsetHeight -
        (document.body.clientHeight - (window.innerHeight + window.scrollY));
      if (b > 0) {
        this.$refs.sidebar.style.bottom = b + "px";
      } else {
        this.$refs.sidebar.style.bottom = "0px";
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    let url = this.$route.path;
    this.current_url = url.substr(0, url.indexOf("#"));
    this.loadIndex();
    this.loadContent(this.$route.path);
  }
};
</script>

<style>
h2::before {
  display: block;
  content: " ";
  margin-top: -3.3em;
  height: 3.3em;
  visibility: hidden;
  pointer-events: none;
}

@media screen and (min-width: 760px) {
  .sidebar {
    /* text-align: left;
    top: 6em;
    background: white;
    position: fixed;
    overflow-y: scroll;
    overflow-x: scroll;
    width: 23em;
    border-right-color: #666666;
    border-right-width: 10px; */

    z-index: 10;
    position: fixed;
    left: 175px;
    top: 66px;
    bottom: 0;
    margin-left: -175px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    line-height: 2em;
  }
}

@media screen and (max-width: 900px) {
  .sidebar {
    z-index: 10;
    left: 0px;
    top: 40px;
    background: #fff;
    transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
    transform: translate(-280px, 0);
  }
}


.current {
  color: #2f80ed;
}
.main-content {
  text-align: left;
  max-width: 990px;
  padding-top: 7rem;
  margin-left: 23em;
}
@media screen and (max-width: 900px) {
  .main-content {
    padding-left: 0;
    margin-left: 0;
  }
}
li {
  text-align: left;
  list-style: none;
}
a {
  text-decoration: none;
  color: #525252;
}
a:hover {
  color: orange;
}

pre code {
  overflow-x: auto;
  color: #525252;
  white-space: pre;
  line-height: 1.6;
  display: block;
  background-color: #f8f8f8;

  border-radius: 2px;
  text-align: left;
  padding: 1.2em;
}

.highlight {
  background-color: #e7e9eb;
  color: #304455;
  padding: 1.3rem;
  border-radius: 2px;
  margin-bottom: 1.5rem;
}

.highlight .cpf {
  color: #408090;
  font-style: italic;
}

.highlight .k,
.kd,
.kr {
  color: #0000cd;
  font-weight: bold;
}

.highlight z.s2 {
  color: #a52a2a;
  font-weight: bold;
}

.highlight .mf {
  color: #ff0000;
  font-weight: bold;
}

.highlight .nc {
  color: #0e84b5;
  font-weight: bold;
}

.highlight .kt {
  color: #d63200;
}

.highlight .o {
  color: #666666;
}

.highlight .c1 {
  color: #008000;
  font-style: italic;
}

em {
  font-style: italic;
  font-weight: 800;
}
</style>
