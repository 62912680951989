<template>
  <Nav />
  <div class="technology-container">
    <div class="container" id="technologies">
      <h2 class="technologies-heading">
        Get the Software
      </h2>
      <br/>
      <h3>Preview release (0.12.0)</h3>
      <br/>
      <a href="/release_notes/dboo-0.12.0-release-notes.html">Release Notes</a>
      <br/>
      <br/>
      <a href="/release_notes/evaluation-license.html">Evaluation License</a>
      <br/>
      <br/>
      <br/>
      <h4>DBOO bindings/API</h4>
      <br/>
      <p><strong>Node.js</strong></p>
      <p>Available platforms:</p>
      <ul>
        <li>Linux, x86_64: Node.js 12, 14, 16, 17 (built/tested on Ubuntu 20.04)</li>
        <li>macOS 10.15+, x86_64 (Intel): Node.js 12, 14, 16, 17</li>
        <li>macOS 10.15+, arm64 (M1): Node.js 16, 17</li>
      </ul>
      <p>Use npm to download API module:</p>
      <p>
        <code>
          npm install dboo
        </code>
      </p>
      <br/>
      <p><strong>C++</strong></p>
      <p>For C++ sdk, please contact us.</p>
      <p>Available platforms:</p>
      <ul>
        <li>Linux, x86_64</li>
        <li>macOS 10.15+, x86_64 (Intel) and arm64 (M1)</li>
      </ul>

      <br/>
      <br/>
      <h4>DBOO Server software</h4>
      <br/>
      <p><strong>macOS, 10.15 or later</strong></p>
      <ul>
        <li>x86_64 (Intel): <a href="/download/dboo-server-0.12.0-macos_10.15_x86_64.pkg">dboo-server-0.12.0-macos_10.15_x86_64.pkg</a></li>
        <li>arm64 (M1): <a href="/download/dboo-server-0.12.0-macos_10.15_arm64.pkg">dboo-server-0.12.0-macos_10.15_arm64.pkg</a></li>
      </ul>
      <p><strong>Linux Ubuntu, 20.04</strong></p>
      <ul>
        <li>x86_64: <a href="/download/dboo-0.12.0-ubuntu20.04-amd64.deb">dboo-0.12.0-ubuntu20.04-amd64.deb</a></li>
      </ul>


    </div>
  </div>
  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

export default {
  name: "Download",
  components: {
    Nav,
    Footer
  }, methods: {
    downloadFile(id, name) {
      const url = `/api/download?id=${id}`;
      axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer'
      }).then((response) => {
        const blob = new Blob([response.data], {type: "application/octet-stream"});
        const link = document.createElement("a");
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      });
    }
  }
}


</script>

<style scoped>
.technology-container {
  margin: 0 3em;
  text-align: left;
}

@media screen and (max-width: 900px) {
  .technology-container {
    margin: 0;
  }
}
.heading {
  font-size: 4rem;
  margin: 2rem 0;
  text-align: center;
  color: #2f80ed;
}

.subheading {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  color: #27ae60;
  letter-spacing: 2px;
  text-align: left;
  padding: 0.5rem 0;
  margin-top: 3rem;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */
  /* margin-bottom: 2rem; */
}

#technologies {
  padding-top: 5rem;
}

.technologies-heading {
  font-style: normal;
  font-weight: bolder;
  font-size: 2.1rem;
  text-align: center;
  letter-spacing: 2px;
  color: #2f80ed;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
</style>
