<template>
  <Nav />
  <div class="whydboo-container">
    <!-- <h1 class="heading">Why DBOO ?</h1> -->
    <div class=" container page-content-container">
      <div class="body" role="main">
        <div class="section" id="overview">
          <h1 class="overview-heading">
            Overview<a
              class="headerlink"
              href="#overview"
              title="Permalink to this headline"
            ></a>
          </h1>
          <p>
            DBOO is a NoSQL database, designed particularly for projects that
            use object oriented programming languages. DBOO provides an easy,
            fast and efficient way to implement distributed applications. It is
            built from the core to take advantage of object oriented design all
            throughout from API to data storage.
          </p>
          <p>
            DBOO is a client / server system, simply like a traditional
            database. The DBOO server installs on a server machine and your
            application connects as a client to the server through the DBOO API.
          </p>
          <!-- <p>
            &lt;img src=”/Images/ClientServer Overview.png” alt=””&gt;
          </p> -->
          <p>
            The API knows how to store objects and linkages between objects.
            When committing objects to the server, the DBOO API knows how to
            find linked objects (through pointers/references) and sends any
            updated or new objects to the server. Retrieving objects is done
            either through select statements (through the C++/Java/C# interface)
            based on member field values or by direct object lookup based on
            object id.
          </p>
          <!-- <p>
            &lt;img src=”/Images/ClientServer Objects Transfer.png” alt=””&gt;
          </p> -->
          <div class="section" id="benefits">
            <h2 class="benefits-heading">
              Benefits<a
                class="headerlink"
                href="#benefits"
                title="Permalink to this headline"
              ></a>
            </h2>
            <h3><strong>Faster Development Cycle</strong></h3>
            <ul>
              <li>
                <p>
                  <strong>
                    Use one language for application programming and data
                    modelling</strong
                  >
                </p>
                <p>
                  If your application requires to be written in an object
                  oriented language, the drawback with existing data storage
                  solutions is usually that you need to use another language for
                  modelling the data for that storage (i.e. if storing in a
                  relational database, you design the tables etc in SQL). Using
                  DBOO, you only use C++, or you only use C# or only Java for
                  example.
                </p>
              </li>
              <li>
                <p><strong>Storage data model same as runtime model</strong></p>
                <p>
                  With existing solutions, you normally end up having to design
                  your classes in both your application language (e.g. C++) and
                  model them in a database tool (or SQL), or you may end up
                  designing file formats. With DBOO, you only model your C++, or
                  C#, or Java classes. There is no need for duplicate data
                  models just for storing your data.
                </p>
              </li>
              <li>
                <p>
                  <strong>No storage to runtime transformation required</strong>
                </p>
                <p>
                  With DBOO, there is no need for coding the input or output
                  code in order to store or retrieve your data. With a minimum
                  of declarative code, the DBOO’s API understands how to convert
                  the runtime objects into the storage format.
                </p>
              </li>
            </ul>
            <h3><strong>Better Data Modelling</strong></h3>
            <ul>
              <li>
                <p>
                  <strong>
                    Object oriented modelling is a better fit to many real world
                    problems</strong
                  >
                </p>
                <p>
                  With DBOO, you can still use all data modelling features of
                  your favourite language. It supports inheritance (including
                  multiple in C++), and polymorphism, and object ownership
                  relations just as you would expect from an object oriented
                  language. These features would be hard or impossible to model
                  in a relational model without a performance impact.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Develop processes and algorithms that fits an object
                    oriented model</strong
                  >
                </p>
                <p>
                  Encapsulation of functionality and generic programming are two
                  of the core strengths in object oriented design. By being able
                  to use inheritance, DBOO makes it easier to create
                  applications the way that was intended with object oriented
                  design. If you are forced to mix two programming paradigms
                  such as relational and object orientation, it requires more
                  effort to create systems that have clear cut interfaces; or
                  where functionality can be reused thanks to polymorphism for
                  example.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Type safe; no casting from database types to runtime types
                    required</strong
                  >
                </p>
                <p>
                  DBOO takes care of any casting, if any. Data is normally
                  stored in it’s primitive binary format. Primitives, strings,
                  pointers, classes are all converted to the expected data
                  types. There is no need for explicit casting.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "WhyDBOO",
  components: {
    Nav,
    Footer
  }
};
</script>
<style scoped>
.whydboo-container {
  margin: 0 3em;
  text-align: left;
  padding-top: 5rem;
}

@media screen and (max-width: 900px) {
  .whydboo-container {
    margin: 0;
  }
}

.heading {
  font-size: 4rem;
  text-align: center;
  color: white;
  text-shadow: -1px 2px 0 #2f80ed, 2px 2px 0 #2f80ed, 2px -1px 0 #2f80ed,
    -1px -1px 0 #2f80ed;
}

.overview-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 2.3rem;
  letter-spacing: 2px;
  text-align: center;
  color: #ff9900;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.benefits-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 2.3rem;
  letter-spacing: 2px;
  text-align: center;
  color: #27ae60;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
/* .technologies-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 2.1rem;
  text-align: center;
  letter-spacing: 2px;
  color: #ffffff;
  background: #2f80ed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.5rem 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
} */
</style>
