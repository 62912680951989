<template>
  <header id="header" class="fixed-top">
    <nav class="navbar navbar-expand-lg ">
      <div class="container-sm">
        <router-link to="/"
          ><img
            class="logo img-fluid"
            src="@/assets/logo.png"
            alt="DBOO logo"
            width="100"
            height="24"
        /></router-link>
        <button
          id="btn-collapse"
          class="navbar-toggler text-secondary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon "
            ><i class="bi bi-list mobile-nav-toggle"></i
          ></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-4 me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/why-dboo" class="nav-link"
                >Why DBOO?</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/technology" class="nav-link"
                >Technology</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <router-link to="/services" class="nav-link">Services</router-link>
            </li> -->
            <li class="nav-item">
              <a href="/reference/latest/overview/dboo.html" class="nav-link"
                >Getting started</a
              >
            </li>
            <li class="nav-item">
              <router-link to="/try-dboo" class="nav-link"
              >Try online!</router-link
              >
            </li>
          </ul>
          <div class="btn-container">
            <a href="/reference/latest/overview/dboo.html">
              <div type="button" class="btn-md btn-gettingStarted mt-0">
                Start Free
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
    <router-view />
  </header>
</template>

<script>
// import Button from "@/components/Button.vue";

export default {
  name: "Nav",
  components: {
    // Button
  }
};
</script>

<style scoped>
.nav-link {
  color: #2f281e;
}
.nav-link:hover {
  color: #2f80ed;
}
/* #header {
  background-color: #ffffff;
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 1em 3em;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
} */

#header {
  width: 100%;
  background-color: #ffffff;
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: all 0.5s;
  z-index: 997;
  border-bottom: 1px solid #e6f2fb;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header .logo {
    font-size: 28px;
  }
}

.logo {
  margin-right: 2em;
}
.btn-download {
  margin-right: 2em;
}
.collapse {
  padding-top: 0.8em;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.8rem;
  line-height: 1;
  background-color: transparent;
  /* border: 1px solid transparent; */
  border-radius: 0.25rem;
  transition: box-shadow 0.15 ease-in-out;
}
</style>
