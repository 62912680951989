<template>
  <div class="aboutus-container">
    <Nav />
    <div class="aboutus-content container">
      <h2 class="text-success font-weight-bold text-center">About us</h2>
      <hr />
      <p class="p1">
        Working with large simulation models and complex object graphs we
        figured the existing way of storing data was ineffecient. Using C++ and
        object oriented paradigms for the applications, but SQL or flat files
        for data, meant a lot of time went into creating duplicate data models
        for the same things, just for storing data. A lot of code was needed for
        converting data from one model to the other. DBOO started out as a small
        project to create a serialization framework for C++, but eventually grew
        into a full-fledged database solution for many object oriented
        languages.
      </p>
      <br />
      <p class="p2">
        Today, with full of energy and enthusiasum, DBOO is still in
        development. We are devoted to provide software development tools that
        help you create applications and solutions faster, easier and better in
        many ways. We also offer services to help you integrate our solutions
        into your existing product lines.
      </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "About us",
  components: {
    Nav,
    Footer
  }
};
</script>

<style scoped>
.aboutus-content {
  height: 80vh;
  margin-top: 10em;
}
.text-success {
  font-weight: bolder;
  text-align: center;
}
.p1 {
  text-align: left;
  margin-top: 3em;
  margin-bottom: 0;
}
.p2 {
  text-align: left;
  margin-top: 0.5em;
}
</style>
