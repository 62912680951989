<template>
  <div class="contact-container">
    <Nav />
    <div class="container">
      <h2>Contact us</h2>
      <h6>Email us, we've got your back!</h6>
      <div class="row">
        <div class="col-4 contact-detail">
          <h5 class="email">Email : hello@dboo.dev</h5>
        </div>
        <div class="col-4 contact-detail">
          <h5 class="email">tom@dboo.dev</h5>
        </div>
        <div class="col-4 contact-detail">
          <h5 class="email">nic@dboo.dev</h5>
        </div>
        <!-- <div class="col-6"><Form /></div> -->
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
// import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Contact",
  components: {
    Nav,
    // Form,
    Footer
  }
};
</script>

<style scoped>
.container {
  height: 80vh;
  margin-top: 10em;
}
.email {
  margin-top: 3em;
}

.phone {
  margin-top: 3em;
}

hr {
  border: 1.3px solid #27ae60;
}
</style>
