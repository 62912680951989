<template>
  <div id="homepg">
    <Nav />
    <Hero />
    <!-- superpowers_section -->
    <div id="superpowers" class="superpowers bg-light">
      <div class="section_title text-center mt-md-5">
        <h2 id="superpowers_title"><strong>SuperPowers</strong></h2>
      </div>
      <div class="container text-center bg-light">
        <div class="card-group cards row ms-0 py-5">
          <div
            class="card col-md-6 col-lg-3 d-flex align-items-md-stretch mb-5 me-4 mb-lg-0"
          >
            <div class="card-body">
              <h4 class="card-title text-center">Approachable</h4>
              <p class="card-text description">
                Already know how to build applications? Read the documentation
                and start building database in no time!
              </p>
              <router-link
                to="/getting-started/dboo-overview"
                class="card-link text-decoration-none"
                >more info...</router-link
              >
            </div>
          </div>
          <div
            class="card col-md-6 col-lg-3 d-flex align-items-md-stretch mb-5 me-4 mb-lg-0"
          >
            <div class="card-body">
              <h4 class="card-title text-center">Accelerate</h4>
              <p class="card-text description">
                No storage to runtime transformation required, and even better,
                using just one language for both application programming and
                data modelling.
              </p>
              <router-link to="why-dboo" class="card-link text-decoration-none"
                >more info...</router-link
              >
            </div>
          </div>
          <div
            class="card col-md-6 col-lg-3 d-flex align-items-md-stretch mb-5 mb-lg-0
          "
          >
            <div class="card-body">
              <h4 class="card-title text-center">
                Progressive
              </h4>
              <p class="card-text description">
                When it comes to real world database problems, DBOO offers the
                most efficient data modelling practice.
              </p>
              <router-link
                to="Technology"
                class="card-link text-decoration-none"
                >more info...</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="technology" class="techology">
      <div class="container">
        <h2 class="technology_title"><strong>Technology</strong></h2>
        <p class="section_subtitle">
          DBOO aims to provide a safe, secure database solution which integrates
          perfectly with object oriented languages.
        </p>
        <p>
          DBOO enables an object oriented data model, easily accessible from
          most OO languages. Model your data with inheritance and
          pointers/references as in any object oriented design. Store complete
          objects graphs and complex structures directly to the database.
          Retrieve/search on member fields or through linked objects. Multi
          platform. Today available for C++, .Net and Node.JS.
        </p>
      </div>
    </div>
    <!-- <Subscribe /> -->
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Hero from "@/components/Hero.vue";
// import Subscribe from "@/components/Subscribe.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Nav,
    Hero,
    // Subscribe,
    Footer
  }
};
</script>

<style scoped>
.home-container {
  padding: 0 0;
}

#superpowers_title {
  color: #2d9cdb;
  animation: colorchange 1s infinite;
}
@keyframes colorchange {
  from {
    color: #2d9cdb;
  }
  to {
    color: #2d9cdb;
  }
}
#superpowers .cards {
  padding: 1em 3em;
  margin-top: 3rem;
}

#superpowers .card {
  padding: 50px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  border: none;
  z-index: 1;
}

#superpowers .card-title {
  color: #124265;
  transition: 0.3;
  font-weight: 700;
  font-size: 1.15rem;
}

#superpowers .card-title:hover {
  color: #3556b1;
}
#superpowers .card-body {
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 0;
}

#superpowers .card:hover {
  color: #124265;
  transform: scale(1.08);
}
.technology_title {
  color: #2d9cdb;
  /* animation: colorchangetech 1s infinite; */
}
/* @keyframes colorchangetech {
  from {
    color: #2d9cdb;
  }
  to {
    color: greenyellow;
  }
} */

#technology {
  margin-top: 5rem;
  min-height: 500px;
}
</style>
