<template>
  <Nav />
  <div class="technology-container">
    <div class="container" id="technologies">
      <h2 class="technologies-heading">
        Technology<a
          class="headerlink"
          href="#technologies"
          title="Permalink to this headline"
        ></a>
      </h2>
      <p>
        The objective with DBOO is to provide a safe, secure data base solution
        which integrates perfectly with object oriented languages.
      </p>
      <p><strong>Data Storage</strong></p>
      <ul>
        <li>
          <p>- Atomic commits and transactional data storage.</p>
          <p>
            It is important that your data is saved safely and that referential
            integrity is maintained at all times. When DBOO returns successfully
            from a commit, you can be sure that the data is stored on disk.
            Storing potentially a large number of objects in one commit, is done
            as one atomic transaction. Either the entire transaction is stored
            successfully, or there are no changes to the data on the server.
          </p>
        </li>
      </ul>
      <p><strong>Data Distribution</strong></p>
      <ul>
        <li>
          <p>- Client / server architecture.</p>
          <p>
            The DBOO system consists of a database server (proprietary back-end)
            and a client interface that links as static or dynamic libraries to
            your application code. On Windows, the server runs as a standard
            Windows service.
          </p>
        </li>
      </ul>
      <p><strong>Distributed Processing</strong></p>
      <ul>
        <li>
          <p>- Object queuing and distribution mechanism.</p>
          <p>
            DBOO has features for distributed object queues, allowing for
            streaming of objects and makes it easy to develop applications
            requiring distributed processing.
          </p>
        </li>
      </ul>
      <p><strong>Non Locking Commits and Requests</strong></p>
      <ul>
        <li>
          <p>
            - Until a transaction is complete, serve the previous version of the
            data.
          </p>
          <p>
            No requests for data will wait for other requests or commits to
            finish. Multiple concurrent commits of the same object will result
            in a concurrent update exception, but all read requests and all
            non-overlapping commits are processed in parallel.
          </p>
        </li>
      </ul>
      <p><strong>Searchable</strong></p>
      <ul>
        <li>
          <p>- Fast 64 bit id lookups</p>
          <p>
            All objects are given a 64 bit object id. When traversing object
            graphs, this is the primary means of finding objects.
          </p>
        </li>
        <li>
          <p>- Query language based on C++</p>
          <p>
            Search for objects based on their member field values. In C++, you
            use a C++ API to build queries. The same syntax is used in other
            languages, although as a string based query. Search fields in sub
            classes, join subsets and return for example of their base class
            type.
          </p>
        </li>
      </ul>

      <p><strong>Integration with OO Languages</strong></p>
      <ul class="simple">
        <li>
          <p>- API for popular Object Oriented Languages:</p>
          <ul>
            <li>
              <p>C++ (Windows, Linux, Mac OS X)</p>
            </li>
            <li>
              <p>.Net (Windows)</p>
            </li>
            <li><p>Node.JS</p></li>
            <li><p>Python (in progress)</p></li>
            <li><p>Java (in progress)</p></li>
          </ul>
        </li>
      </ul>
      <p><strong>Command line tool</strong></p>
      <ul>
        <li>
          <p>- Manage databases, user and access groups.</p>
          <p>
            The primary tool for managing a dboo server lets you create and
            modify users, databases and access groups in both interactive and
            batch mode. Command completion makes it easy to learn.
          </p>
        </li>
        <li>
          <p>- Query tool</p>
          <p>
            Same query language as used in C++ and through the API can be used
            in command line tool, with auto completion, making it easier to
            learn.
          </p>
        </li>
      </ul>

      <p>
        Using DBOO enables you to design and develop truly object oriented
        solutions; allowing you to take full advantage of the benefits of object
        oriented design such as code reuse and encapsulation; and not having to
        compromise because of your data storage options.
      </p>
      <p>
        DBOO is not an object-relational mapping solution. That means that at no
        point is there a translation from object model to relational model.
      </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Technology",
  components: {
    Nav,
    Footer
  }
};
</script>
<style scoped>
.technology-container {
  margin: 0 3em;
  text-align: left;
}

@media screen and (max-width: 900px) {
  .technology-container {
    margin: 0;
  }
}
.heading {
  font-size: 4rem;
  margin: 2rem 0;
  text-align: center;
  color: #2f80ed;
}

.subheading {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  color: #27ae60;
  letter-spacing: 2px;
  text-align: left;
  padding: 0.5rem 0;
  margin-top: 3rem;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */
  /* margin-bottom: 2rem; */
}

#technologies {
  padding-top: 5rem;
}

.technologies-heading {
  font-style: normal;
  font-weight: bolder;
  font-size: 2.1rem;
  text-align: center;
  letter-spacing: 2px;
  color: #2f80ed;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
</style>
