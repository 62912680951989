<template>
  <Nav />
  <div class="tryit-container">
    <div class="container" id="tryit">
      <p/>.
      <p/>.
      <p/>.
      <BTabs v-model="tab_index">
        <BTab title="Docker daily use" active :style="{ height: '100%'}">
          <div class="panel-body scrollable-panel" style="background-color: white; height: 100%; border: 1px solid lightgray;">
            <p class="text-block">Number of sessions:{{number_of_sessions}}</p>
            <p class="text-block">Active sessions:{{number_of_active_sessions}}</p>
            <p></p>
            <h4>Daily usage</h4>
            <div style="width: 95%; height:200px;">
              <vue3-chart-js
                  ref="chartRef"

                  :id="barChart.id"
                  :type="barChart.type"
                  :options="barChart.options"
                  :data="barChart.data"
              />
            </div>

            <b-table striped hover :items="daily_runs">
            </b-table>
          </div>
        </BTab>
        <BTab title="Docker containers" active :style="{ height: '100%'}">
          <div class="panel-body scrollable-panel" style="background-color: white; height: 100%; border: 1px solid lightgray;">
            <b-table striped hover :items="containers">
            </b-table>
            <h4>Raw Docker output</h4>
            <p class="text-block">Docker output:{{docker_message}}</p>
          </div>
        </BTab>
      </BTabs>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

import axios from 'axios';
import {BTabs, BTab, BTable} from 'bootstrap-vue-3';
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: "TryItStats",
  components: {
    Nav,
    Footer,
    BTabs, BTab,
    BTable,
    Vue3ChartJs
  },
  setup() {
    const chartRef = ref(null);

    const smallBars = {
          type: "bar",
          options: {
            min: 0,
            max: 100,
            responsive: false,
            scales: {
              y: {
                display: false
              },
              x: {
                display: false
              },
            }
          }
    };
    const barChart = {
      id: "barChart",
          type: "bar",
          options: {
            min: 0,
            max: 100,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
            },
            scales: {
              y: {
                min: 0,
                max: 100,
                title: {
                  display: true,
                  text: 'Runs',
                  align: 'center',
                }
              }
            }
          },
      data: {
        labels: [],
        datasets: []
      }
    };
    return {smallBars, chartRef, barChart};
  },
  mounted() {
    this.getStats();
  },
  data() {
    return {
      tab_index: 0,
      docker_message: "",
      containers: [{
        docker_id: "id",
        session_id: "sessionid",
        name: "name",
        uptime: "uptime",
        runtime_value: "runtime_value",
        runtime_unit: "runtime_unit"
      }],
      daily_runs: [{date: "", number_of_runs: 0, number_of_attempts: 0}],
      number_of_sessions: "",
      number_of_active_sessions: "",
      last_30days_runs: [],
      last_30days_attempts: [],
      stats_last_date: Date.now(),
      data: [],
    }
  },

  methods: {
    getStats: function() {
      let self = this;
      let token = this.$route.params.token;
      axios.get(`/api/stats?token=${token}`, { })
          .then(response => {
            if (response.data && response.data) {
              self.files = response.data;
              console.log('res: ', response.data);
              self.update_output(response.data);
            }
          })
          .catch(e => {
          });

    },
    update_output(message) {
      this.docker_message = message.docker_message;
      this.number_of_sessions = message.number_of_sessions;
      this.number_of_active_sessions = message.number_of_active_sessions;
      this.last_30days_runs = message.last_30days_runs_by_the_minute;
      this.last_30days_attempts = message.last_30days_attempts_by_the_minute;
      this.stats_last_date = message.stats_last_date;
      this.update_chart();
    },
    addDays(old_date, days) {
      var date = new Date(old_date.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    },
    update_chart() {
      console.log('update_chart');
      // dates on x axis
      let labels = [];
      let current_date = new Date(this.stats_last_date);
      for (let i = 0; i < 30; ++i) {
        labels.push(current_date.toLocaleDateString());
        current_date = this.addDays(current_date, -1);
      }
      labels = labels.reverse();
      // sums for each day
      let runs_data = [];
      let attempts_data = [];
      for (let i = 29; i >= 0; --i) {
        let daily_numbers = this.last_30days_runs[i];
        const daily_sum = daily_numbers ? daily_numbers.reduce((partialSum, a) => partialSum + a, 0) : 0;
        let attempts_numbers = this.last_30days_attempts[i];
        const attempts_sum = attempts_numbers ? attempts_numbers.reduce((partialSum, a) => partialSum + a, 0) : 0;
        runs_data[i] = daily_sum;
        attempts_data[i] = attempts_sum;
      }

      this.barChart = { ... this.barChart, data: {
        labels: labels.reverse(),
          datasets: [
            {
              label: "Total runs per day",
              backgroundColor: '#54a967',
              data: runs_data.reverse()
            },
            {
              label: "Total attempts per day",
              backgroundColor: '#3f7ddf',
              data: attempts_data.reverse()
            }
          ]
      }};
      this.chartRef.data.labels = this.barChart.data.labels;
      this.chartRef.data.datasets = this.barChart.data.datasets;
      const max_y = Math.max.apply(null, [...runs_data,...attempts_data]);
      const margin_y_scale = 5;
      this.chartRef.options.scales.y.min = 0;
      this.chartRef.options.scales.y.max = max_y + margin_y_scale;
      this.chartRef.update(250);
      let runs = [];
      for (let i = 0; i < 30; ++i) {
        runs.push({
          date: labels[i],
          number_of_runs: runs_data[i],
          number_of_attempts: attempts_data[i],
        });
      }
      this.daily_runs = runs;
      console.log(this.barChart);
    }
  }
}
</script>

<style>

.tryit-container {
  margin: 0 3em;
  text-align: left;
  height: 100vh;
}

hr {
  border: 1.3px solid #27ae60;
}

</style>
