<template>
  <Nav />
  <div class="services-container">
    <h1 class="heading"><strong>Services</strong></h1>
    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><strong>Consulting</strong></h5>
            <p class="card-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
            <a href="#" class="btn btn-primary">Contact us for more info..</a>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><strong>Training</strong></h5>
            <p class="card-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
            <a href="#" class="btn btn-primary">Contact us for more info..</a>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title"><strong>Technical Support</strong></h5>
            <p class="card-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
            <a href="#" class="btn btn-primary">Contact us for more info..</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Services",
  components: {
    Nav,
    Footer
  }
};
</script>
<style scoped>
.heading {
  font-size: 4rem;
  font-style: bold;
  text-align: center;
  color: #ff9900;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.card {
  margin: 3rem 3rem;
  max-width: 388px;
  max-height: 424px;
}
.card-body {
  border: 3px solid #27ae60;
  box-sizing: border-box;
  border-radius: 18px;
  padding: 3rem;
}
</style>
