<template>
  <div class="tree-menu">
    <div class="label-wrapper" @click="toggleChildren">
      <div :style="indent" :class="labelClasses">
        <i v-if="nodes" class="fa" :class="iconClasses"></i>{{'\xa0'}}
        <a href="#" @click="labelClicked" :class="labelSelected">{{ label }}</a>
      </div>
    </div>
    <tree-menu
        v-if="showChildren"
        v-for="node in nodes"
        :nodes="node.nodes"
        :label="node.label"
        :node="node"
        :depth="depth + 1"
        :onClick="onClick"
        :getSelected="getSelected"
    >
    </tree-menu>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    nodes: Array,
    depth: Number,
    onClick: Function,
    node: Object,
    getSelected: Function
  },
  data() {
    return { showChildren: true };
  },
  name: 'tree-menu',
  computed: {
    iconClasses() {
      return {
        'fa-plus-square-o': !this.showChildren,
        'fa-minus-square-o': this.showChildren
      }
    },
    labelClasses() {
      return {
        'no-children': !this.nodes,
        'has-children': this.nodes
      }
    },
    labelSelected() {
      return { 'is-selected': this.getSelected() === this.node }
    },
    indent() {
      return { transform: `translate(${this.depth * 10}px)`}
    },
    is_selected() {
      return  this.getSelected() === this.node;
    }
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    labelClicked() {
      this.onClick(this.node);
    }
  }
}
</script>
<style>
  .label-wrapper {
    background: white;
    font-family: Lato, proxima-nova, Helvetica Neue, Arial, sans-serif;
    font-size: .85em;
    color: black;
    padding-bottom: 1px;
    margin-bottom: 1px;
  }
  .has-children {
    cursor: pointer;
  }
  .no-children {
    margin-left: .3em;
  }
  .is-selected {
    font-weight: bold;
  }
  .label-wrapper a {
    color: black;
  }
  .top {
    border-bottom: 1px solid #000;
  }
</style>
