import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import WhyDBOO from "../views/WhyDBOO.vue";
import Technology from "../views/Technology.vue";
import Download from "../views/Download.vue";
import Services from "../views/Services.vue";
import Docs from "../views/Docs.vue";
import GetStarted from "../views/GetStarted.vue";
import Contact from "../views/Contact.vue";
import Aboutus from "../views/Aboutus.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import Concept from "../views/Concept.vue";
import Overview from "../views/Overview.vue";
import Installation from "../views/Installation.vue";
import TryIt from "../views/TryIt.vue";
import TryItStats from "../views/TryItStats.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/why-dboo",
    name: "WhyDBOO",
    component: WhyDBOO
  },
  {
    path: "/technology",
    name: "Technology",
    component: Technology
  },
  {
    path: "/services",
    name: "Services",
    component: Services
  },
  {
    path: "/docs",
    name: "Docs",
    component: Docs
  },
  {
    path: "/getting-started/:id",
    name: "GetStarted",
    component: GetStarted
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/about-us",
    name: "About us",
    component: Aboutus
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: TermsAndConditions
  },
  {
    path: "/concept",
    name: "Concept",
    component: Concept
  },
  {
    path: "/overview",
    name: "Overview",
    component: Overview
  },
  {
    path: "/reference",
    name: "Reference",
    component: Overview
  },
  {
    path: "/preview-0.10.7/download",
    redirect: "/preview-0.11.0/download"
  },
  {
    path: "/preview-0.11.0/download",
    redirect: "/preview/download"
  },
  {
    path: "/preview/download",
    name: "Downloads",
    component: Download
  },
  {
    path: "/try-dboo",
    name: "Try DBOO",
    component: TryIt
  },
  {
    path: "/stats/:token",
    name: "Stats",
    component: TryItStats
  },
  {
    path: "/installation",
    name: "Installation",
    component: Installation
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
