<template>
  <Nav />
  <div class="docs-container d-flex flex-row .justify-content-md-between">
    <!-- <div class="sidebar p-2" role="navigation" aria-label="main navigation"> -->

    <div class="fix-sidebar p-3 ">
      <div class="sidebar-heading">DBOO 0.8.0 Documentation</div>
      <ul>
        <li>
          <a href="#introduction">Introduction</a>
        </li>
        <li>
          <router-link to="/installation"
            ><a href="#">Installation</a></router-link
          >
        </li>
        <li>
          <router-link to="/getstarted"
            ><a href="#">Getting Started</a></router-link
          >
        </li>
        <li>
          <router-link to="/concept"
            ><a href="#" class="current">Concept</a></router-link
          >
        </li>
        <ul>
          <li>
            <a href="#inserting-retrieving-objects"
              >Inserting/Retrieving Objects</a
            >
          </li>

          <li>
            <a href="#pointers-references-and-relationships"
              >Pointers, References and Relationships</a
            >
          </li>
          <li>
            <a href="#object-graphs-deep-copies-light-references"
              >Object Graphs, Deep Copies & Light References</a
            >
          </li>
          <li>
            <a href="#inheritance-polymorphism">Inheritance & Ploymorphism</a>
          </li>
        </ul>
        <li>
          <router-link to="/overview"><a href="#">Overview</a></router-link>
        </li>
        <br />
        <li><a href="#">class odb</a></li>
        <li><a href="#">class obj-id</a></li>
        <li><a href="#">class cls_base</a></li>
        <li><a href="#">class ref</a></li>
        <li><a href="#">Exceptions</a></li>
      </ul>
    </div>

    <div class="main-content p-4">
      <div class="content">
        <div class="section" id="concept">
          <h1>
            Concept<a
              class="headerlink"
              href="#concept"
              title="Permalink to this headline"
            ></a>
          </h1>
          <p>
            The design goal of DBOO database was to create a distributed
            computing platform that will help with development in object
            oriented languages. The most important objective was to make the
            interface to the system as easy as possible to use for an
            application developer. This would mean that objects as you use them
            in your application would be the means of inserting and retrieving
            data.
          </p>
          <div class="section" id="inserting-retrieving-objects">
            <h2>
              Inserting / Retrieving Objects<a
                class="headerlink"
                href="#inserting-retrieving-objects"
                title="Permalink to this headline"
              ></a>
            </h2>
            <p>
              Inserting and retrieving data is always done with objects defined
              in the language of choice. When you select objects, you always
              select complete objects, rather than as in a relation database
              where you select the fields you want to retrieve. Also, when you
              insert or update objects, you always insert or update the whole
              object, not just single fields. (This may change in future
              releases.)
            </p>
          </div>
          <div class="section" id="pointers-references-and-relationships">
            <h2>
              Pointers, References and Relationships<a
                class="headerlink"
                href="#pointers-references-and-relationships"
                title="Permalink to this headline"
              ></a>
            </h2>
            <p>
              Another important aspect of selecting objects is that objects’
              relationships in an object oriented language are deterministic.
              One object may refer or point to one or more other unique objects;
              the reference or pointer used for this relationship is not subject
              to the values of an object’s member fields.
            </p>
            <p>
              If the closest comparison to an object in a relation database is a
              record in a table, it is easy to see one of the most important
              differences between a relational model and an object model. In a
              relational database, relationships between records are expressed
              as conditions on the record’s columns or fields, while in the
              object oriented model the member fields have no impact on which
              object a reference is pointing to.
            </p>
          </div>
          <div class="section" id="object-graphs-deep-copies-light-references">
            <h2>
              Object Graphs, Deep Copies &amp; Light References<a
                class="headerlink"
                href="#object-graphs-deep-copies-light-references"
                title="Permalink to this headline"
              ></a>
            </h2>
            <p>
              When you design your object model, you will most likely end up
              with a number of classes with pointers/references to other
              classes. When using DBOO, and inserting an object pointing to
              other objects, the DBOO will make a deep copy, following through
              all pointers in all objects that are reachable by the object
              inserted.
            </p>
            <p>
              The same applies for selecting objects. The database will return
              all objects pointed to by the selected objects (and recursively
              return all objects that were inserted in the first place).
            </p>
            <p>
              To prevent loading the entire object model through these recursive
              lookups, a special ‘light’ reference type is available (in C++,
              osl::ref&lt;&gt;). It overloads the pointer operator, and thus
              acts like a pointer. However, the recursive lookups will stop with
              the reference. Only once the application tries to access the
              pointed-to object will a request for loading it be sent to the
              server.
            </p>
            <p>Objects are retrieved from the server using either:</p>
            <dl class="field-list simple">
              <dt class="field-odd">Select queries</dt>
              <dd class="field-odd">
                <p>
                  object can be selected based on type or member field values
                  using select queries expressed in C++, C# or Java code.
                </p>
              </dd>
              <dt class="field-even">Pointers</dt>
              <dd class="field-even">
                <p>
                  objects linked with pointers (C++) or references (C#,Java)
                  will load when the queried object is loaded and linked in
                  runtime.
                </p>
              </dd>
              <dt class="field-odd">Light references</dt>
              <dd class="field-odd">
                <p>
                  using pointer semantics (in C++), light references to objects
                  will only load the referenced objects as they are accessed.
                </p>
              </dd>
              <dt class="field-even">Named object</dt>
              <dd class="field-even">
                <p>objects can be given an id or a name.</p>
              </dd>
            </dl>
          </div>
          <div class="section" id="inheritance-polymorphism">
            <h2>
              Inheritance &amp; Polymorphism<a
                class="headerlink"
                href="#inheritance-polymorphism"
                title="Permalink to this headline"
              ></a>
            </h2>
            <p>
              The possibly strongest points for an object oriented data model is
              inheritance for data modelling and polymorphism for generic
              programming. These two features enables code reuse and clean cut
              interfaces that are difficult or requires substantial amount of
              effort to implement using other data modelling methodologies.
            </p>
            <p>
              The ability to create hierarchies of data types makes it easy to
              model real life entities. It also makes it very easy to extend the
              data model without touching existing data types, thus ensuring
              that existing functionality can still work without modification,
              yet at the same time being able to reuse what is already in place.
            </p>
            <p>
              DBOO supports most of the inheritance structures that C++, Java
              and C# can implement. This enables you to take advantage of an
              object oriented design without having to develop the functionality
              required for storing and retrieving objects, as would be required
              if non-object oriented storage solutions were used.
            </p>
            <p>
              Polymorphism in DBOO is the ability to select on fields in a base
              type and retrieve objects of all sub classes. Select queries can
              be applied to any level of the class hierarchy. You can also
              select on a base class field, but only return objects of one sub
              class.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Concept",
  components: {
    Nav,
    Footer
  }
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  z-index: 10;
  top: 61px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-heading {
  font-weight: bold;
}
.current {
  color: #2f80ed;
}
.main-content {
  padding-right: 1rem;
  text-align: left;
  max-width: 700px;
  padding: 35px 0;
  padding-left: 50px;
}
li {
  text-align: left;
  list-style: none;
}
a {
  text-decoration: none;
  color: #525252;
}
a:hover {
  color: orange;
}

pre code {
  overflow-x: auto;
  color: #525252;
  white-space: pre;
  line-height: 1.6rem;
  display: block;
  background-color: #f8f8f8;
  margin: 0 2px;
  border-radius: 2px;
  text-align: left;
  padding: 1.2em 1.4em;
}

.simple-li {
  list-style-type: inherit;
}

.highlight .cp {
  color: #007020;
}

.highlight .cpf {
  color: #408090;
  font-style: italic;
}

.highlight .k {
  color: #007020;
  font-weight: bold;
}

.highlight .nc {
  color: #0e84b5;
  font-weight: bold;
}

.highlight .kt {
  color: #d63200;
}

.highlight .o {
  color: #666666;
}

.highlight .c1 {
  color: #707070;
  font-style: italic;
}
</style>
