<template>
  <div class="footer-container sticky-buttom">
    <div class="container-fluid d-flex justify-content-evenly">
      <div>
        <router-link to="/"
          ><img src="@/assets/logo.png" alt="DBOO logo" width="100" height="24"
        /></router-link>
      </div>
      <div>
        <ul class="nav">
          <li class="nav-item">
            <router-link class="nav-link" to="/about-us"
              ><small>About us</small></router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact"
              ><small>Contact</small></router-link
            >
          </li>
        </ul>
      </div>
<!--      <div>
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" href="#">
              <h3><i class="bi bi-github"></i></h3>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <h3><i class="bi bi-twitter"></i></h3>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <h3><i class="bi bi-linkedin"></i></h3>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <h3><i class="bi bi-facebook"></i></h3>
            </a>
          </li>
        </ul>
      </div>-->
    </div>
    <div class="inner-footer text-center">
      <hr />
      <p><small> &#169; 2022 DBOO. All rights reserved.</small></p>
      <div>
        <ul class="nav d-flex justify-content-center">
          <li class="nav-item">
            <router-link class="nav-link" to="/privacy-policy"
              ><small>Privacy Policy</small></router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/terms-and-conditions"
              ><small>Terms and Conditions</small></router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Form.vue";
export default {
  name: "Footer",
  components: Form
};
</script>

<style scoped>
.footer-container {
  padding: 3em 3em;
  background-color: #f8f8f8;
  margin-bottom: 0;
}
a {
  font-size: 0.8 rem;
  color: #2c3e50;
}

a:hover {
  color: #f29948;
}
</style>
