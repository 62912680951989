<template>
  <div class="page-container">
    <Nav />
    <div class="page-content">
      <h2 class="text-success font-weight-bold">Privacy Policy</h2>
      <hr />
      <div class="page-list">
        <h3>Introduction.</h3>
        <p>
          This Privacy Policy governs the use and disclosure by DBOO of personal
          information of yours, and outlines how DBOO collects, and processes
          your information, and what your rights and how to processing of your
          personal data when the you access or use any of our websites and
          products, when you communicate or interact with us regardless of your
          choice o f the form and the medium.
        </p>
        <br />
        <h3>Information that DBOO may collect.</h3>
        <div>
          <strong>Evaluation License Holders</strong>
          <p>
            For users downloading the evaluation license, the following personal
            information is collected:
          </p>
          <p>
            - Name User name and password - Email Address
            <strong>Commercial License Holders</strong>
            For users downloading the commercial license, the following personal
            information is collected: - User name and password - Company Name -
            Address - Telephone Number - Email Address Forum Users For users of
            the forum, the following personal information is collected: - Email
            Address - Username & Password This information will be collected
            with your knowledge and participation. The information for
            commercial license holders is received from PayPal as part of the
            purchase process. If you choose not to provide this information,
            DBOO may decline to accept your registration. It will not normally
            be possible for you to deal with DBOO anonymously.
            <strong>Cookies</strong>
            DBOO's Web site may track user access sessions using cookies,
            without your specific knowledge or participation, by automated means
            in the course of your use of DBOO' network. Cookies are a Web
            browser technology that is used by DBOO to improve your experience
            of using its Web site, but are not used to record any personal
            information. You may disable cookies in your Web browser at the cost
            of some possible loss of functionality in your use of the DBOO Web
            site.
          </p>
        </div>

        <br />
        <h3>Sensitive information</h3>
        <p>
          DBOO will not collect information revealing your racial or ethnic
          origin, political opinions, religious or philosophical beliefs,
          trade-union membership, or details of health, disability or sexual
          activity or orientation, unless compelled to do so by law.
        </p>
        <br />
        <h3>Purposes for which information is collected</h3>
        <p>
          The personal information collected by DBOO may be used for the
          following purposes: To administer the evaluation and commercial
          license holders and our forum; For billing purposes; For any other
          purpose for which you would reasonably expect your personal
          information to be used by DBOO; and For any other purpose authorised
          The General Data Protection Regulation (EU) Principles set out in the
          Privacy Act - 2018.
        </p>
        <br />
        <h3>Disclosure of your personal information</h3>
        <p>
          Your personal information may be disclosed in the following
          circumstances: - To authorised officers of DBOO and their agents for
          the purpose of administering DBOO' evaluation and commercial licenses;
          - Under compulsion of law, for example if a warrant or court order is
          received; - To lessen or prevent a serious and imminent threat to an
          individual's life or health; - To you or with your consent; or - for
          any other reason authorised The General Data Protection Regulation
          (EU) Principles set out in the Privacy Act - 2018. Otherwise DBOO will
          use its reasonable endeavours to avoid the disclosure of your personal
          information, save that aggregated demographic information may be
          disclosed so long as the information is unable to identify you or any
          other individual.
        </p>
        <br />
        <h3>Your rightAccessing your personal information</h3>
        <p>
          You can contact DBOO to request a copy of your personal information by
          emailing our Privacy Officer at privacy@dboo.dev. The Privacy Officer
          shall attend to your request on a confidential basis within 30 days of
          the request being received.
        </p>
        <br />
        <h3>Fees for access to personal information</h3>
        <p>
          No fee will be levied for access to information described in clause 2
          above.
        </p>
        <br />
        <h3>Refusal of access</h3>
        <p>
          Access to your personal information may be refused if: - providing
          access would pose a serious and imminent threat to life or health of a
          person; - providing access would create an unreasonable impact on the
          privacy of others; - the request is frivolous and vexatious; - denial
          of access is authorised or required by law; or - for any other reason
          authorised The General Data Protection Regulation (EU) Principles set
          out in the Privacy Act - 2018.
        </p>
        <br />
        <h3>Data quality</h3>
        <p>
          DBOO will use its reasonable endeavours to ensure the accuracy and
          quality of the information collected about you. Should personal
          information that you know DBOO has collected about you change, it is
          your responsibility to bring the changes to DBOO' attention which you
          may do by email to the Privacy Officer at privacy@dboo.dev.
        </p>
        <br />
        <h3>Data security</h3>
        <p>
          DBOO will endeavour to keep your personal information as secure as
          reasonably possible. Without limitation, registration information is
          not accessible over the Internet, but only from a secure
          password-protected internal workstation. DBOO's Internet server
          utilises a modern Linux-based and Windows Server based operating
          system that is maintained with the latest available security patches
          and updates.
        </p>
        <br />
        <h3>Amendments to Privacy Policy</h3>
        <p>
          DBOO may amend the terms applying to the use of your personal
          information at any time, subject to informing you of the amendments by
          publishing the revised policy on its Web site.
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Privacy Policy",
  components: {
    Nav,
    Footer
  }
};
</script>

<style scoped>
.page-content {
  margin: 3em 3em;
}
.page-list {
  text-align: left;
}
.text-success {
  font-weight: bolder;
}
</style>
