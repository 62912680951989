<template>
  <Nav/>
  <div class="tryit-container">
    <div  id="tryit">
      <p/>
      <Splitpanes  class="default-theme"  vertical :style="{ width:'100%' }" @resize="resized($event)">
        <Pane size="10" >
          <BTabs >
            <BTab title="Try DBOO!" active :style="{ height: '100%'}">
            </BTab>
          </BTabs>
          <Splitpanes  class="default-theme"  horizontal :style="{ height:'75vh' }" @resize="resized($event)">
            <Pane>
              <div :style="{height:'100%', overflow:'scroll'}" >
          <tree-menu :label="tree.label" :nodes="tree.nodes" :depth="0" :onClick="menuClicked" :getSelected="getSelected">
          </tree-menu>
              </div>
              </Pane>
          </Splitpanes>
        </Pane>
        <Pane>
      <BTabs v-model="tab_index">
        <BTab v-for="file in files" :title="file.name" active :style="{ height: '100%'}">
        </BTab>
        <BTab title="Query tool" active :style="{ height: '100%'}">
        </BTab>
      </BTabs>
      <Splitpanes  class="default-theme"  horizontal :style="{ height:'75vh' }" @resize="resized($event)">
        <Pane>
          <v-ace-editor
              v-for="(file,index) in files"
              v-model:value="file.code"
              v-show="tab_index === index"
              ref="editors"
              @init="editorInit"
              :lang="lang"
              :theme="theme"
              style="height: 100%;
              border-bottom: 1px solid lightgray;
              border-left: 1px solid lightgray;
              border-right: 1px solid lightgray;
              margin: auto;"
          />
          <v-ace-editor
              v-model:value="query"
              v-show="tab_index === files.length"
              ref="query"
              @init="editorInit"
              :lang="lang"
              :theme="theme"
              style="height: 100%; border: 1px solid lightgray; margin: auto;"
          />
        </Pane>
      <Pane size="20">
        <div class="panel-body scrollable-panel" style="background-color: white; height: 100%; border: 1px solid lightgray;">
          <vue-recaptcha ref="recaptcha"
                         :sitekey="sitekey"
                         v-show="showRecaptcha"
                         @verify="onVerify"
                         @expired="onExpired"
                         @error="onError"
          />
          <p class="text-block">{{ command_output }}</p><br>
        </div>
      </Pane>
    </Splitpanes>
      <button @click="run_cpp" style="width:10%">Run</button>
      <input v-model="args" placeholder="Command line arguments..."  style="width:90%"/>
        </Pane>
      </Splitpanes>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import { VAceEditor } from 'vue3-ace-editor';
import TreeMenu from "@/views/TreeMenu"
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/theme-eclipse';

import axios from 'axios';
import { VueRecaptcha } from 'vue-recaptcha';
import {BTabs, BTab} from 'bootstrap-vue-3';
import {Splitpanes, Pane} from 'splitpanes';
import 'splitpanes/dist/splitpanes.css'

export default {
  name: "TryIt",
  components: {
    Nav,
    Footer,
    VueRecaptcha,
    BTabs, BTab,
    Splitpanes, Pane,
    VAceEditor,
    TreeMenu
  },
  setup() {
    const args = "";
    return {
      args,
    }
  },
  mounted() {
    let coderef = this.getCodeRef();
    if (!coderef) {
      coderef = ['simple/main'];
    }
    console.log("coderef: ", coderef);
    this.getCode(coderef);
    let ace = this.$refs.query._editor;
    ace.commands.addCommand({
      name: "run query",
      exec: function() {
        let line = ace.getCursorPosition().row;
        let query = ace.session.getLine(line);
      },
      bindKey: {mac: "Command-Enter", win: "Ctrl-Enter"}
    });
  },
  data() {
    return {
      code: '#include <iostream>\n\nint main(int,char**) {\n  return 0;\n}\n',
      command_output: '',
      sitekey: '6LejYF0gAAAAAKejGdvs3WDERRPq0ba8U_Hv_8K5',
      files: [{name:"main.cpp", code:"A"}, {name:"types.h", code:"B"}],
      query: "",
      tab_index: 0,
      default_files: [],
      options: {"showPrintMargin": false},
      lang: "c_cpp",
      theme: "eclipse",
      showRecaptcha: true,
      selectedNode: null,
      tree: {
        label: 'Examples',
        nodes: [

          {
            label: 'Data types',
            nodes: [
              {
                label: 'C++',
                nodes: [
                  {
                    label: 'Composition',
                    example: 'client/composition.cpp'
                  },
                  {
                    label: 'Containers',
                    example: 'client/containers.cpp'
                  },
                  {
                    label: 'Inheritance',
                    example: 'client/inheritance.cpp'
                  },
                  {
                    label: 'Pointers',
                    example: 'client/pointers.cpp'
                  },
                  {
                    label: 'Primitives',
                    example: 'client/primitives.cpp'
                  },
                  {
                    label: 'Strings',
                    example: 'client/strings.cpp'
                  }
                  ]
              },
              {
                label: 'Javascript',
                nodes: [
                  {
                    label: 'Composition',
                    example: 'client/composition.js'
                  },
                  {
                    label: 'Containers',
                    example: 'client/containers.js'
                  },
                  {
                    label: 'Inheritance',
                    example: 'client/inheritance.js'
                  },
                  {
                    label: 'Pointers',
                    example: 'client/pointers.js'
                  },
                  {
                    label: 'Primitives',
                    example: 'client/primitives.js'
                  },
                  {
                    label: 'Strings',
                    example: 'client/strings.js'
                  },
                  {
                    label: 'Dates',
                    example: 'client/dates.js'
                  }]
              }
              ]
          },
          {
            label: 'Other',
            nodes: [
              {
                label: 'C++',
                nodes: [
                  {
                    label: 'Simple example',
                    example: 'simple/main.cpp'
                  }]
              }]
          },
          {
            label: 'Queries',
            nodes: [
              {
                label: 'C++',
                nodes: [
                  {
                    label: 'set_comparator',
                    example: 'queries/set_comparator.cpp'
                  },
                  {
                    label: 'set_operator',
                    example: 'queries/set_operator.cpp'
                  },
                  {
                    label: 'set_operator',
                    example: 'queries/value_comparator.cpp'
                  },
                  {
                    label: 'init_data',
                    example: 'queries/init_data.cpp'
                  },
                  {
                    label: 'init_db',
                    example: 'queries/init_db.cpp'
                  }

                ]
              },
              {
                label: 'Javascript',
                nodes: [
                  {
                    label: 'set_comparator',
                    example: 'queries/set_comparator.js'
                  },
                  {
                    label: 'set_operator',
                    example: 'queries/set_operator.js'
                  },
                  {
                    label: 'set_operator',
                    example: 'queries/value_comparator.js'
                  },
                  {
                    label: 'init_data',
                    example: 'queries/init_data.js'
                  },
                  {
                    label: 'init_db',
                    example: 'queries/init_db.js'
                  }

                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    exampleClicked(example) {
      this.getCode(example);
    },
    editorInit() {
    },
    resized( e) {
    },
    menuClicked(node) {
      if (node && 'example' in node) {
        this.selectedNode = node;
        this.getCode(node.example);
      }
    },
    getSelected() {
      return this.selectedNode;
    },
    getCodeRef: function () {
      let urlParams = new URLSearchParams(window.location.search);
      let ref = urlParams.get('example');
      history.pushState(
          {},
          null,
          "/try-dboo"
      )
      return ref;
    },
    getCode: function(example) {
      let self = this;
      axios.get(`/api/get_example`, { params: { example: example } })
          .then(response => {
            if (response.data && response.data) {
              self.files = response.data;
            }
          })
          .catch(e => {
            self.update_output(e.error);
          });

    },
    onVerify: function (response) {
      this.recaptcha = response;
      this.showRecaptcha = false;
    },
    onExpired: function () {
      this.recaptcha = '';
      this.showRecaptcha = true;
    },
    onError: function (e) {
      this.recaptcha = '';
    },
    update_output(message) {
      this.command_output = message;
    },
    run_cpp() {
      const self = this;
      const build = async function(code_by_value) {
        let done = false;
        let lang_is_cpp = self.files.findIndex(item => item.name.endsWith(".cpp")) != -1;
        let lang_is_js = self.files.findIndex(item => item.name.endsWith(".js")) != -1;
        let lang = lang_is_cpp ? "cpp" : lang_is_js ? "js" : "";
        let args = self.args;
        if (self.tab_index == self.files.length) {
          // query rather than building/running c++ or js or something
          lang = "query";
          if (code_by_value) {
            args = code_by_value;
          } else {
            let ace = self.$refs.query._editor;
            let line = ace.getCursorPosition().row;
            args = ace.session.getLine(line);
          }
        }
        await axios.post("/api/run", {files: self.files, lang: lang, args: args, recaptcha: self.recaptcha})
            .then(response => {
              if (!response.data.result) {
                done = true;
                self.update_output(response.data.message);
              }
            })
            .catch(e => {
              done = true;
              self.update_output(e.error);
            });
        const starttime = Date.now().valueOf();
        const checkStatus = async function() {
          // fetch status update and output
          console.log('fetching status');
          await axios.get("/api/status", {})
              .then(response => {
                console.log('status:', response);
                done = response.data.state === 'ready';
                self.update_output(response.data.data);
              });
          if (!done && Date.now().valueOf() < (starttime + 30000)) {
            window.setTimeout(checkStatus,150);
          }
        }
        if (!done) {
          window.setTimeout(checkStatus, 150);
        }
      }
      build();
    }
  }
}
</script>

<style scoped>

.tryit-container {
  margin: 0 3em;
  text-align: left;
  height: 100vh;
}

.scrollable-panel {
  overflow-y:scroll;
  width:100%;
  height:15vh;
}
.text-block {
  white-space: pre;
  font-family: Courier, "Source Code Pro", Arial, monospace;
  font-size: small;
}
.panel-resizable {
  resize: vertical;
  overflow: auto
}
hr {
  border: 1.3px solid #27ae60;
}
#tryit {
  padding-top: 5rem;
}
.tryit-heading {
  font-style: normal;
  font-weight: bolder;
  font-size: 2.1rem;
  text-align: center;
  letter-spacing: 2px;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 1;
  padding: 0px;
  height: 90%;
  max-height: 90%;
  min-height: 90%;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
.nwc1 {
  flex: 0 1 auto;
}
.editor {
  flex: 1 1 auto;
}

</style>
