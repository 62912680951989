<template>
  <div class="btn-container">
    <router-link to="/getting-started/dboo-overview">
      <div type="button" class="btn-lg btn-gettingStarted shadow-lg">
        Start Free
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "Button"
};
</script>

<style>
/* .btn-gettingStarted {
  color: #fff;
  background-color: #2487ce;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  transition: all 0.3s ease-in-out;
  animation: colorchange1 5s infinite; 
} */

.btn-gettingStarted {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 14px 50px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #2487ce;
}

/* @keyframes colorchange1 {
  from {
    background-color: #2d9cdb;
  }
  to {
    background-color: #ff307a;
  }
} */
.btn-gettingStarted:hover {
  background-color: #3194db;
  transform: scale(1.02);
}
</style>
